import { CHART_TYPES } from '@/types/chart-types.js';

function getDefaultState() {
  return {
    charts: [
      [
        {
          selectedChartType: CHART_TYPES.BAR,
          xAxis: ['instanceFilename'],
          yAxis: ['instanceFilename'],
        },
      ],
    ],
    selectedChartIndex: [0, 0], // [row, column]
    // controls which options/inputs are visible to control the active chart in chartControls.vue
    chartConfig: {
      xAxis: true,
      yAxis: true,
      xAxisGrouped: false,
    },
  };
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    updateCharts(state, charts) {
      state.charts = charts;
    },
    setSelectedChartType(state, chartType) {
      state.charts[state.selectedChartIndex[0]][
        state.selectedChartIndex[1]
      ].selectedChartType = chartType;
      state.chartConfig = getDefaultState().chartConfig;
    },

    setChartConfig(state, chartConfig) {
      state.chartConfig = {
        ...getDefaultState().chartConfig,
        ...chartConfig,
      };
    },

    setXAxis(state, xAxis) {
      state.charts[state.selectedChartIndex[0]][
        state.selectedChartIndex[1]
      ].xAxis = xAxis;
    },

    setYAxis(state, yAxis) {
      state.charts[state.selectedChartIndex[0]][
        state.selectedChartIndex[1]
      ].yAxis = yAxis;
    },

    setSelectedChartIndex(state, selectedChartIndex) {
      state.selectedChartIndex = selectedChartIndex;
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    defaultChartConfig: () => getDefaultState().chartConfig,
    getSelectedChartData: (state) => {
      if (state.selectedChartIndex) {
        return state.charts[state.selectedChartIndex[0]][
          state.selectedChartIndex[1]
        ];
      } else {
        return null;
      }
    },
    getChartData: (state) => (rowIndex, columnIndex) => {
      return state.charts[rowIndex][columnIndex];
    },
  },
};
