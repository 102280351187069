import Vue from 'vue';
import Buefy from 'buefy';
import VueCompositionAPI from '@vue/composition-api';
import VeeValidate, { Validator } from 'vee-validate';
import Notifications from 'vue-notification';
import { VAsync, VForm } from 'vuetensils/src/components';
import VTooltip from 'v-tooltip';
import VueShortKey from 'vue-shortkey';
import * as vClickOutside from 'v-click-outside-x';
import VueLayers from 'vuelayers/src/index.js';
import i18n from '../lang.js';
import ZoomifySource from './vuelayers-suppl/zoomify-source';
import RasterSource from './vuelayers-suppl/raster-source';
import TranslateInteraction from './vuelayers-suppl/translate-interaction';
import RotateInteraction from './vuelayers-suppl/rotate-interaction';

Validator.extend(`positive`, (value) => Number(value) > 0);

Vue.use(VueCompositionAPI);
Vue.use(Buefy, { defaultIconPack: `fas` });
Vue.use(Notifications);
Vue.use(VTooltip);
Vue.use(vClickOutside);
Vue.use(VueLayers);
Vue.use(ZoomifySource);
Vue.use(RasterSource);
Vue.use(TranslateInteraction);
Vue.use(RotateInteraction);
Vue.use(VueShortKey, {
  prevent: [`input`, `textarea`, `.ql-editor`],
});
Vue.use(VeeValidate, {
  i18nRootKey: `validations`,
  i18n,
  inject: false,
});
Vue.component('VAsync', VAsync);
Vue.component('VForm', VForm);
