<template>
  <div class="job-details-wrapper relative" style="min-height:150px;">
    <BLoading
      :is-full-page="false"
      :active.sync="loading"
      style="height:150px;width:100%;text-align:center;"
    />
    <template v-if="!loading">
      <table class="w-auto">
        <tr>
          <!-- eslint-disable-next-line prettier/prettier -->
          <td class="label">
            {{ $t('model') }}:
          </td>
          <td>{{ training.model }}</td>
        </tr>
        <tr>
          <!-- eslint-disable-next-line prettier/prettier -->
          <td class="label">
            {{ $t('training-images') }}:
          </td>
          <td>
            <div v-for="image in training.train_image_ids" :key="image">
              {{ image.image_name }}
            </div>
          </td>
        </tr>
        <tr>
          <!-- eslint-disable-next-line prettier/prettier -->
          <td class="label">
            {{ $t('validation-images') }}:
          </td>
          <td>
            <div v-for="image in training.validation_image_ids" :key="image">
              {{ image.image_name }}
            </div>
          </td>
        </tr>
        <tr>
          <!-- eslint-disable-next-line prettier/prettier -->
          <td v-if="trainingDetails && trainingDetails.checkpoints && trainingDetails.checkpoints.length" class="label">
            {{ $t('checkpoints') }}:
          </td>
          <td>
            <div
              v-for="checkpoint in trainingDetails.checkpoints"
              :key="checkpoint.file_path"
            >
              {{ $t('epoch') }}: {{ checkpoint.epoch }} {{ $t('step') }}:
              {{ checkpoint.step }} -
              {{ checkpoint.file_path }}
            </div>
          </td>
        </tr>
        <tr v-if="errors.length">
          <!-- eslint-disable-next-line prettier/prettier -->
          <td class="label">
            {{ $t('errors') }}:
          </td>
          <td>
            <div v-for="error in errors" :key="error" class="color-red">
              {{ error }}
            </div>
          </td>
        </tr>
        <tr v-if="properties && properties.length">
          <!-- eslint-disable-next-line prettier/prettier -->
          <td class="label">
            {{ $t('x-axis') }}:
          </td>
          <td>
            <CytomineMultiselect
              v-model="xAxis"
              :options="xAxisOptions"
              :allow-empty="false"
              :searchable="false"
            />
          </td>
        </tr>
        <tr v-if="properties && properties.length">
          <!-- eslint-disable-next-line prettier/prettier -->
          <td class="label">
            {{ $t('y-axis') }}:
          </td>
          <td>
            <CytomineMultiselect
              v-model="selectedProperty"
              :options="properties"
              :allow-empty="false"
              :searchable="false"
              multiple
            />
          </td>
        </tr>
      </table>

      <div v-if="properties && properties.length" class="ml-3">
        <LineChart
          :x-axis="xAxis"
          :y-axis="selectedProperty"
          :data="cleansedData"
        />
      </div>
    </template>
  </div>
</template>

<script>
import LineChart from '@/components/project/visualizations/charts/LineChart.vue';
import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';
import { GetTrainingStatus } from '@/services/trainingFramework.js';

export default {
  name: 'TrainingDetails',
  components: {
    LineChart,
    CytomineMultiselect,
  },
  props: {
    training: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      trainingDetails: {},
      loading: true,
      selectedProperty: '',
      data: [],
      errors: [],
      xAxis: 'milliseconds',
      xAxisOptions: ['milliseconds', 'epoch', 'step'],
    };
  },
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    cleansedData() {
      let currentStep = null;
      let currentEpoch = null;

      return this.data.map((data) => {
        if (data.step !== null && data.step !== undefined) {
          currentStep = data.step;
        }
        if (data.epoch !== null && data.epoch !== undefined) {
          currentEpoch = data.epoch;
        }
        return {
          ...data,
          milliseconds: new Date(data._time).getTime(),
          epoch: currentEpoch,
          step: currentStep,
        };
      });
    },
    properties() {
      const props = [];
      for (const propertyObject of this.data) {
        for (const key of Object.keys(propertyObject)) {
          if (!props.includes(key) && key !== '_time') {
            props.push(key);
          }
        }
      }
      return props;
    },
  },
  async mounted() {
    this.getTrainingStatus();
    this.errors = this.getErrorMessages();
  },
  methods: {
    getErrorMessages() {
      // 'inputs' and 'parameters' both contain error messages for some reason
      // they will not be provided in the response unless they contain values
      const errors = [];

      const errorMessages = this.training.inputs || this.training.parameters;
      if (errorMessages) {
        errors.push(
          errorMessages.map((error) => error.code + ': ' + error.message)
        );
      } else {
        if (
          this.training.valohai_job_status
            .toLowerCase()
            .includes('internal server error')
        ) {
          errors.push(
            'Training failed before reaching Valohai. Please contact the AI team for support.'
          );
        }
      }
      return errors;
    },
    async getTrainingStatus() {
      this.loading = true;

      try {
        const response = await GetTrainingStatus(this.training.pid);

        if (!response) {
          this.errors.push('No response from server');
          return;
        }

        this.trainingDetails = response;

        if (response.metadata) {
          this.data = response.metadata;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
td {
  border-bottom: none !important;
}
.label {
  margin-bottom: 0;
  font-size: 1em;
}
</style>
