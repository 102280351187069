<template>
  <div
    id="horizontal-bar-chart-container"
    style="position: relative; text-align: center"
  >
    <div id="container" />
    <b-message
      v-if="nullValues.length > 0 && showNullValues"
      type="is-info"
      has-icon
      size="is-small"
      style="display: inline-block"
      class="mt-5"
    >
      {{ nullValues.length }} {{ $t('slides-excluded') }}
    </b-message>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { isNullOrUndefined } from '@/utils/string-utils.js';

export default {
  name: 'LineChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    xAxis: {
      type: String,
      required: true,
    },
    yAxis: {
      type: Array,
      required: true,
    },
    dataId: {
      type: String,
      default: 'id',
      required: false,
    },
    showNullValues: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    chart: null,
    nullValues: [],
  }),
  computed: {
    /** @returns {{ id: number, name: string}} */
    project() {
      return this.$store.state.currentProject.project;
    },
  },
  watch: {
    xAxis() {
      this.buildChart();
    },
    yAxis() {
      this.buildChart();
    },
  },
  mounted() {
    this.buildChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  },
  methods: {
    getSeriesData() {
      const series = [];
      for (const yAxis of this.yAxis) {
        const line = {
          name: yAxis,
          data: this.data.map((data) => {
            if (
              isNullOrUndefined(data[this.xAxis]) ||
              isNullOrUndefined(data[yAxis])
            ) {
              this.nullValues.push(data);
              return null;
            }
            return {
              y: data[yAxis],
              x: data[this.xAxis],
              id: '',
            };
          }),
        };
        line.data = line.data.filter((a) => a); // filter out null values
        series.push(line);
      }

      return series;
    },
    buildChart() {
      const seriesData = this.getSeriesData();
      // @ts-ignore
      this.chart = Highcharts.chart('container', {
        chart: {
          height: '400px',
        },
        title: {
          text: `Metrics by ${this.xAxis}`,
        },
        xAxis: {
          labels: {
            enabled: true,
          },
          title: {
            text: this.xAxis,
          },
        },
        yAxis: {
          title: {
            text: 'Metrics',
          },
          labels: {
            overflow: 'justify',
          },
        },
        plotOptions: {
          bar: {
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            cursor: 'pointer',
          },
        },
        credits: {
          enabled: false,
        },
        series: seriesData,
      });
    },
  },
};
</script>

<style scoped></style>
