<script>
/** @module rotate-interaction/interaction */
import RotateFeatureInteraction from 'ol-rotate-feature';
import { fromOlEvent as observableFromOlEvent } from 'vuelayers/src/rx-ext/from-ol-event.js';
import interaction from 'vuelayers/src/mixin/interaction.js';
import stylesContainer from 'vuelayers/src/mixin/styles-container.js';
import { defaultEditStyle, createStyle } from 'vuelayers/src/ol-ext/style.js';
import { isCollection, isVectorSource } from 'vuelayers/src/ol-ext/util.js';
import { mapValues, isFunction } from 'vuelayers/src/util/minilo.js';
import mergeDescriptors from 'vuelayers/src/util/multi-merge-descriptors.js';
import { hasInteraction } from 'vuelayers/src/util/assert.js';
import { makeWatchers } from 'vuelayers/src/util/vue-helpers.js';

const props = {
  /**
   * Source or collection identifier from IdentityMap.
   */
  source: {
    type: String,
    required: true,
  },
  /**
   * Initial angle in radians (positive is counter-clockwise), applied for features already added to collection.
   */
  angle: {
    type: Number,
    default: 0,
  },
};

/**
 * @vueMethods
 */
const methods = {
  /**
   * @returns {Promise<ol.interaction.Rotate>}
   * @protected
   */
  async createInteraction() {
    const sourceIdent = this.makeIdent(this.source);
    let source = await this.$identityMap.get(
      sourceIdent,
      this.$options.INSTANCE_PROMISE_POOL
    );
    if (isFunction(source.getFeatures)) {
      const features = source.getFeatures();
      if (isCollection(features)) {
        source = features;
      }
    }
    return new RotateFeatureInteraction({
      source: isVectorSource(source) ? source : undefined,
      features: isCollection(source) ? source : undefined,
      deleteCondition: this.deleteCondition,
      insertVertexCondition: this.insertVertexCondition,
      pixelTolerance: this.pixelTolerance,
      style: this.createStyleFunc(),
      wrapX: this.wrapX,
    });
  },
  /**
   * @returns {ol.StyleFunction}
   * @protected
   */
  getDefaultStyles() {
    const defaultStyles = mapValues(defaultEditStyle(), (styles) =>
      styles.map(createStyle)
    );
    return function __selectDefaultStyleFunc(feature) {
      if (feature.getGeometry()) {
        return defaultStyles[feature.getGeometry().getType()];
      }
    };
  },
  /**
   * @returns {object}
   * @protected
   */
  getServices() {
    return mergeDescriptors(
      interaction.methods.getServices.call(this),
      stylesContainer.methods.getServices.call(this)
    );
  },
  /**
   * @returns {ol.interaction.Interaction|undefined}
   * @protected
   */
  getStyleTarget() {
    return this.$interaction;
  },
  /**
   * @returns {void}
   * @protected
   */
  mount() {
    interaction.methods.mount.call(this);
  },
  /**
   * @returns {void}
   * @protected
   */
  unmount() {
    interaction.methods.unmount.call(this);
  },
  /**
   * @returns {void}
   * @protected
   */
  subscribeAll() {
    subscribeToInteractionChanges.call(this);
  },
  /**
   * @param {Array<{style: ol.style.Style, condition: (Function | boolean | undefined)}> | ol.StyleFunction | Vue | undefined} styles
   * @returns {void}
   * @protected
   */
  setStyle(styles) {
    if (styles !== this._styles) {
      this._styles = styles;
      this.scheduleRefresh();
    }
  },
};

const watch = makeWatchers(
  ['source'],
  () =>
    function () {
      this.scheduleRecreate();
    }
);

/**
 * @vueProto
 * @alias module:rotate-interaction/interaction
 * @title vl-interaction-rotate
 */
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'vl-interaction-rotate',
  mixins: [interaction, stylesContainer],
  props,
  watch,
  methods,
};

/**
 * @private
 */
function subscribeToInteractionChanges() {
  hasInteraction(this);
  const rotateEvents = observableFromOlEvent(this.$interaction, [
    'rotatestart',
    'rotateend',
  ]);
  this.subscribeTo(rotateEvents, (evt) => {
    ++this.rev;
    this.$emit(evt.type, evt);
  });
}
</script>
