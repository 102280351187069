<template>
  <div
    v-click-outside="deactivate"
    :class="['navbar-item', displayResults ? 'is-active' : '']"
  >
    <BField :type="error ? 'is-danger' : null" class="m-0">
      <BInput
        v-model="searchString"
        :placeholder="$t('search')"
        :loading="loading"
        :disabled="error"
        class="max-w-160"
        icon="search"
        @click.native="activate()"
      />
      <div class="control">
        <RouterLink
          class="button"
          to="/advanced-search"
          active-class="router-link-active"
          @click.native="deactivate"
        >
          +
        </RouterLink>
      </div>
    </BField>

    <div v-show="true" class="navbar-dropdown">
      <h2 class="m-0 py-1 px-4 size-12 weight-6 uppercase bg-gray-1">
        {{ $t('study') }} ({{ filteredProjects.length }})
      </h2>
      <div v-if="filteredProjects.length > 0">
        <RouterLink
          v-for="project in subsetProjects"
          :key="project.id"
          :to="`/project/${project.id}`"
          class="navbar-item block m-0 py-1 px-4 weight-4 color-inherit"
          @click.native="deactivate"
          v-html="highlightedName(project.name)"
        />
        <span v-if="moreProjects" class="navbar-item">...</span>
      </div>
      <span v-else class="navbar-item">{{ $t('study-none') }}</span>

      <h2 class="m-0 py-1 px-4 size-12 weight-6 uppercase bg-gray-1">
        {{ $t('images') }} ({{ filteredImages.length }})
      </h2>
      <p v-if="filteredImages.length > 0">
        <RouterLink
          v-for="img in subsetImages"
          :key="img.id"
          :to="`/project/${img.project}/image/${img.id}`"
          class="navbar-item block m-0 py-1 px-4 weight-4 color-inherit"
          @click.native="deactivate"
          v-html="htmlImageName(img)"
        />
        <a v-if="moreImages" class="navbar-item">...</a>
      </p>
      <span v-else class="navbar-item">{{ $t('no-image') }}</span>

      <div
        v-if="moreImages || moreProjects"
        class="border-t border-gray-2 pt-2 text-center"
      >
        <RouterLink
          :to="`/advanced-search/${searchString}`"
          class="button is-small"
          @click.native="deactivate"
        >
          {{ $t('button-view-all') }} ({{ totalNbResults }})
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { ImageInstanceCollection, ProjectCollection } from 'cytomine-client';
import { getWildcardRegexp } from '@/utils/string-utils.js';

export default {
  name: 'CytomineSearcher',
  data() {
    return {
      isActive: false,
      loading: false,
      error: false,
      searchString: '',
      projects: [],
      images: [],
      maxNbDisplayed: 5,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },

    displayResults() {
      return this.isActive && !this.error && this.searchString.length > 0;
    },
    regexp() {
      return getWildcardRegexp(this.searchString);
    },
    filteredProjects() {
      if (!this.searchString) {
        return this.projects;
      }

      return this.projects.filter((project) => {
        return this.regexp.test(project.name);
      });
    },
    subsetProjects() {
      return this.filteredProjects.slice(0, this.maxNbDisplayed);
    },
    moreProjects() {
      return this.filteredProjects > this.subsetProjects;
    },
    filteredImages() {
      if (!this.searchString) {
        return this.images;
      }

      return this.images.filter((image) => {
        return this.regexp.test(this.imageName(image));
      });
    },
    subsetImages() {
      return this.filteredImages.slice(0, this.maxNbDisplayed);
    },
    moreImages() {
      return this.filteredImages > this.subsetImages;
    },
    totalNbResults() {
      return this.filteredImages.length + this.filteredProjects.length;
    },
  },
  methods: {
    async fetchImages() {
      this.images = await ImageInstanceCollection.fetchAllLight();
    },
    async fetchProjects() {
      this.projects = (
        await new ProjectCollection({
          light: true,
          filterKey: 'user',
          filterValue: this.currentUser.id,
        }).fetchAll()
      ).array;
    },
    async activate() {
      if (!this.isActive) {
        try {
          this.loading = true;
          await Promise.all([this.fetchImages(), this.fetchProjects()]);
        } catch (error) {
          console.log(error);
          this.error = true;
        }
        this.loading = false;
        this.isActive = true;
      }
    },
    deactivate() {
      this.isActive = false;
    },
    imageName(image) {
      return String(image.blindedName || image.instanceFilename);
    },
    highlightedName(value) {
      return value.replace(this.regexp, '<strong>$1</strong>');
    },
    htmlImageName(img) {
      const blindIndication = img.blindedName
        ? `<span class="size-12 uppercase">[${this.$t(
            'blinded-name-indication'
          )}] </span>`
        : '';
      const inProject = `<span class="in-project">(${this.$t('in-project', {
        projectName: img.projectName,
      })})</span>`;
      return `${blindIndication}${this.highlightedName(
        this.imageName(img)
      )}&nbsp;${inProject}`;
    },
  },
};
</script>

<style scoped>
.navbar-item:not(.is-active) .navbar-dropdown {
  /* display dropdown if inactive even on mobile */
  display: none;
}
</style>
