<template>
  <VInput
    v-bind="$attrs"
    :classes="{
      text: {
        label: !new Set(['radio']).has($attrs.type),
        'visually-hidden': hideLabel,
      },
      input: {
        input: $attrs.type !== 'radio',
        textarea: $attrs.type === 'textarea',
      },
    }"
    :name="name"
    :options="options"
    v-on="$listeners"
  >
    <template #description="input">
      <div v-if="input.error" class="help is-danger">
        <span v-if="input.invalid.type">
          {{ errors.type || `Must be of type: ${$attrs.type}.` }}
        </span>
        <span v-if="input.invalid.required">
          {{ errors.type || `This field is required.` }}
        </span>
        <span v-if="input.invalid.minlength">
          {{ errors.type || `Must be longer than ${$attrs.minlength}.` }}
        </span>
        <span v-if="input.invalid.maxlength">
          {{ errors.type || `Must be shorter than ${$attrs.maxlength}.` }}
        </span>
        <span v-if="input.invalid.min">
          {{ errors.type || `Must be greater than ${$attrs.min}.` }}
        </span>
        <span v-if="input.invalid.max">
          {{ errors.type || `Must be less than ${$attrs.max}.` }}
        </span>
        <span v-if="input.invalid.max">
          {{ errors.type || `Incorrect pattern.` }}
        </span>
      </div>
    </template>
  </VInput>
</template>

<script>
import VInput from 'vuetensils/src/components/VInput/VInput.vue';

export default {
  name: 'IdxInput',
  components: {
    VInput,
  },
  inheritAttrs: false,
  model: {
    event: 'update',
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    hideLabel: Boolean,
  },
};
</script>

<style>
.vts-input--error .vts-input__input {
  border-color: #f14668; /* Taken from Bulma */
}
.vts-input--select {
  /* Taken from Bulma */
  position: relative;
}
.vts-input--select::after {
  /* Taken from Bulma */
  content: ' ';
  display: block;
  position: absolute;
  bottom: 1em;
  right: 1em;
  width: 0.75em;
  height: 0.75em;
  border: 3px solid #3273dc;
  border-right: 0;
  border-top: 0;
  pointer-events: none;
  transform: rotate(-45deg);
  transform-origin: center;
}
.vts-input--select select {
  /* Taken from Bulma */
  padding-right: 2.5em;
}
</style>
