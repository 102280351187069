<template>
  <div class="tags-wrapper">
    <BLoading v-if="loading" :is-full-page="false" active />
    <em v-else-if="error" class="mr-2">{{ $t('error-fetch-tags') }}</em>
    <div v-else class="flex gap-8 align-center">
      <BTaglist
        v-for="(association, idx) in associatedTags"
        :key="association.id"
        attached
        class="m-0"
      >
        <BTag type="is-info" class="m-0">
          {{ association.tagName.toUpperCase() }}
        </BTag>
        <BTag v-if="canEdit" class="m-0">
          <button
            :title="$t('remove')"
            class="border-0 p-0 color-gray-4"
            @click="removeTag(association, idx)"
          >
            <BIcon icon="times-circle" size="is-small" class="m-0" />
            <span class="visually-hidden">{{
              `${$t('remove')} ${association.tagName}`
            }}</span>
          </button>
        </BTag>
      </BTaglist>

      <IdxBtn v-if="canEdit" small @click="displayModal">
        {{ $t('add') }}
      </IdxBtn>
    </div>
  </div>
</template>

<script>
import AddTagDomainAssociationModal from '@/components/tag/AddTagDomainAssociationModal.vue';
import noteApi from '@/services/noteApi.js';

export default {
  name: 'CytomineTags',
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    object: { type: Object, required: true },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      addTagModal: false,
      associatedTags: [],
    };
  },
  async created() {
    try {
      this.loading = true;
      const object = this.object;
      const response = await noteApi.get(
        `/api/domain/${object.class}/${object.id}/tag_domain_association.json.json?max=0&offset=0`
      );
      this.associatedTags = response.collection;
      this.sortAssociatedTags();
    } catch (error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  },
  methods: {
    displayModal() {
      // this.addTagModal = true;
      // required to use programmatic modal because the description is sometimes displayed in elements with a
      // CSS transform (e.g. popover) that conflict with the fixed position of the modal
      // (http://meyerweb.com/eric/thoughts/2011/09/12/un-fixing-fixed-elements-with-css-transforms/)

      this.$buefy.modal.open({
        parent: this,
        component: AddTagDomainAssociationModal,
        props: { associatedTags: this.associatedTags },
        hasModalCard: true,
        events: { addObjects: this.addAssociations },
      });
    },
    sortAssociatedTags() {
      this.associatedTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
    },
    async addAssociations(tags) {
      if (tags.length === 0) return;

      const object = this.object;
      const existingTags = [];
      let newTags = [];

      for (const element of tags) {
        if (element.class === 'be.cytomine.meta.Tag') {
          existingTags.push(element);
        } else {
          newTags.push(element);
        }
      }

      try {
        const newTagRequests = newTags.map((tagname) => {
          return noteApi.post('/api/tag.json', { json: { name: tagname } });
        });
        const responses = await Promise.all(newTagRequests);

        newTags = responses.map((response) => {
          return response.tag;
        });

        tags = existingTags.concat(newTags);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-add-tags'),
        });
      }

      try {
        const associationRequests = tags.map((tag) => {
          return noteApi.post(
            `/api/domain/${object.class}/${object.id}/tag_domain_association.json`,
            {
              json: {
                domainClassName: object.class,
                domainIdent: object.id,
                tag: tag.id,
              },
            }
          );
        });
        const responses = await Promise.all(associationRequests);
        const newAssocations = responses.map((response) => {
          return response.tagdomainassociation;
        });
        this.associatedTags = this.associatedTags.concat(newAssocations);
        this.sortAssociatedTags();
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-add-tag-domain-association'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-add-tag-domain-associations'),
        });
      }
    },
    async removeTag(association, idx) {
      try {
        await noteApi.delete(
          `/api/tag_domain_association/${association.id}.json`
        );
        this.associatedTags.splice(idx, 1);
        this.$emit('update');
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('unexpected-error-info-message'),
        });
      }
    },
  },
};
</script>

<style>
.tags-wrapper .tag {
  background-color: rgba(0, 0, 0, 0.04);
}

.tags-wrapper .tag.is-dark {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
}
</style>
