<template>
  <div>
    <VAsync :await="request">
      <template #default="promise">
        <div>
          <BLoading v-if="promise.pending" :is-full-page="true" active />
          <div class="p-3">
            <IdxTable
              :await="request"
              :data="jobs"
              :current-page.sync="currentPage"
              :per-page.sync="perPage"
              :sort-by.sync="sortBy"
              :sort-direction.sync="sortDirection"
              :total="total"
              class="mt-4"
              detailed
              detail-key="id"
            >
              <template #default>
                <BTableColumn
                  v-slot="props"
                  :label="$t('digital-assay')"
                  field="name"
                  sortable
                  width="1000"
                >
                  {{ props.row.assayName }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('run-number')"
                  width="500"
                  centered
                >
                  {{ props.row.number }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('launched-by')"
                  field="username"
                  sortable
                  width="1000"
                >
                  {{ props.row.username }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('runtime')"
                  width="1000"
                >
                  {{ getRuntime(props.row) }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('execution-date')"
                  field="created"
                  sortable
                  width="1000"
                >
                  {{ props.row.created }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('status')"
                  field="status"
                  sortable
                  centered
                  width="1000"
                >
                  <JobStatus :status="props.row.status" />

                  <!-- Allow running jobs to be cancelled -->
                  <div v-if="props.row.status === 2">
                    <IdxBtn
                      link
                      style="font-size:0.8em;"
                      @click="() => cancelJob(props.row.id)"
                    >
                      {{ $t('cancel') }}
                    </IdxBtn>
                  </div>
                </BTableColumn>
              </template>

              <template #detail="{row: job}">
                <JobDetails :job="job" />
              </template>

              <template #empty>
                <div class="content has-text-grey has-text-centered">
                  <p>{{ $t('no-analysis-run') }}</p>
                </div>
              </template>
            </IdxTable>
          </div>
        </div>
      </template>
    </VAsync>
  </div>
</template>

<script>
import noteApi from '../../services/noteApi.js';
import IdxTable, { DEFAULTS } from '../utils/IdxTable.vue';
import jobStatusMapping from '../../utils/job-utils.js';
import JobDetails from './JobDetails.vue';
import JobStatus from './JobStatus.vue';

export default {
  name: 'ListAssays',
  components: {
    JobStatus,
    IdxTable,
    JobDetails,
  },
  data: () => ({
    loading: true,
    error: false,
    launchModal: false,
    availableSoftwares: [],
    availableLaunchers: [],
    revision: 0,
    jobCollection: {},
    request: null,

    creationModal: false,

    softwares: [],
    allJobs: [],

    searchString: '',
    sortBy: 'created',
    sortDirection: 'desc',
    perPage: DEFAULTS.perPage,
    currentPage: 1,
    total: 0,
  }),
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    /** @returns {boolean} */
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    /** @returns {Job[]} */
    jobs() {
      const { sortBy, sortDirection, allJobs: jobs } = this;

      const sortDir = sortDirection === 'asc' ? 1 : -1;

      return jobs.sort((a, b) => {
        if (a[sortBy] === b[sortBy]) return 0;

        const order = a[sortBy] > b[sortBy] ? 1 : -1;
        return order * sortDir;
      });
    },
  },

  watch: {
    currentPage: {
      handler() {
        this.fetchJobs();
      },
      immediate: true,
    },
  },

  methods: {
    availableStatus() {
      return Object.keys(jobStatusMapping).map((key) => {
        return {
          label: this.$t(jobStatusMapping[key].label),
          status: key,
        };
      });
    },

    formatJobs(jobs) {
      const jobsDateUpdated = jobs.map((job) => {
        job.created = new Date(job.created);
        return job;
      });
      return jobsDateUpdated;
    },

    getRuntime(assay) {
      const startDate = new Date(assay.created);
      let endDate = new Date();

      if (assay.jobDataStatuses?.length) {
        const lastStatus =
          assay.jobDataStatuses[assay.jobDataStatuses.length - 1];
        endDate = new Date(lastStatus.created);
      }

      const seconds = Math.round(
        Math.abs(startDate.getTime() - endDate.getTime()) / 1000
      );

      if (seconds < 60) {
        return `${seconds}s`;
      } else {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
      }
    },

    fetchJobsRequest(queryParams) {
      return noteApi.get(`napi/project/${this.project.id}/job`, {
        query: queryParams,
      });
    },

    fetchJobs() {
      const queryParams = {
        page: this.currentPage - 1,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      };

      const fetchRequest = async () => {
        try {
          /** @type {{results: Array, total: number}} */
          // @ts-ignore
          const response = await this.fetchJobsRequest(queryParams);
          this.allJobs = this.formatJobs(response.results);
          this.total = response.total;

          if ((this.currentPage - 1) * this.perPage >= this.total) {
            this.currentPage = 1;
          }
        } catch (error) {
          console.log(error);
          this.error = error;
        }
      };
      this.request = fetchRequest(); // assign to a promise instead of a function, no duplicate requests
    },

    async cancelJob(jobId) {
      await noteApi.post(`napi/job/${jobId}/cancel`);
      this.fetchJobs();
    },
  },
};
</script>
