<template>
  <div>
    <div
      v-if="!configUI['project-jobs-tab'] && !currentUser.admin"
      class="box error"
    >
      <h2>{{ $t('access-denied') }}</h2>
      <p>{{ $t('insufficient-permission') }}</p>
    </div>
    <div v-else class="list-jobs-wrapper content-wrapper">
      <div class="panel">
        <div class="panel-heading">
          {{ $t('analysis') }}
        </div>

        <BTabs
          v-model="activeTab"
          :animated="false"
          position="is-centered"
          class="py-5"
        >
          <BTabItem key="assays" value="assays" label="Assays">
            <ListAssays />
          </BTabItem>
          <BTabItem
            v-if="currentUser.adminByNow"
            key="training"
            value="training"
            label="Training"
          >
            <ListTrainings />
          </BTabItem>
        </BTabs>
      </div>
    </div>
  </div>
</template>

<script>
import ListAssays from './ListAssays.vue';
import ListTrainings from './ListTrainings.vue';

export default {
  name: 'ListJobs',
  components: {
    ListAssays,
    ListTrainings,
  },
  data: () => ({}),
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
  },
};
</script>
<style scoped>
.tab-item:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
