var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VInput',_vm._g(_vm._b({attrs:{"classes":{
    text: {
      label: !new Set(['radio']).has(_vm.$attrs.type),
      'visually-hidden': _vm.hideLabel,
    },
    input: {
      input: _vm.$attrs.type !== 'radio',
      textarea: _vm.$attrs.type === 'textarea',
    },
  },"name":_vm.name,"options":_vm.options},scopedSlots:_vm._u([{key:"description",fn:function(input){return [(input.error)?_c('div',{staticClass:"help is-danger"},[(input.invalid.type)?_c('span',[_vm._v(" "+_vm._s(_vm.errors.type || ("Must be of type: " + (_vm.$attrs.type) + "."))+" ")]):_vm._e(),(input.invalid.required)?_c('span',[_vm._v(" "+_vm._s(_vm.errors.type || "This field is required.")+" ")]):_vm._e(),(input.invalid.minlength)?_c('span',[_vm._v(" "+_vm._s(_vm.errors.type || ("Must be longer than " + (_vm.$attrs.minlength) + "."))+" ")]):_vm._e(),(input.invalid.maxlength)?_c('span',[_vm._v(" "+_vm._s(_vm.errors.type || ("Must be shorter than " + (_vm.$attrs.maxlength) + "."))+" ")]):_vm._e(),(input.invalid.min)?_c('span',[_vm._v(" "+_vm._s(_vm.errors.type || ("Must be greater than " + (_vm.$attrs.min) + "."))+" ")]):_vm._e(),(input.invalid.max)?_c('span',[_vm._v(" "+_vm._s(_vm.errors.type || ("Must be less than " + (_vm.$attrs.max) + "."))+" ")]):_vm._e(),(input.invalid.max)?_c('span',[_vm._v(" "+_vm._s(_vm.errors.type || "Incorrect pattern.")+" ")]):_vm._e()]):_vm._e()]}}])},'VInput',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }