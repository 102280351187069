<template>
  <main id="app" class="w-full h-full flex flex-column">
    <notifications
      class="mt-4 notification-container"
      position="top center"
      max-width="600"
      :max="5"
    >
      <template #body="props">
        <div
          :class="props.item.type"
          class="
            vue-notification
            flex flex-row-reverse
            justify-between
            align-center
            radius-4
          "
        >
          <button class="delete" @click="props.close">
            {{ $t('delete') }}
          </button>
          <div>
            <strong class="color-inherit">
              {{ props.item.title }}
            </strong>
            <div v-html="props.item.text" />
          </div>
        </div>
      </template>
    </notifications>

    <template v-if="!loading">
      <div v-if="communicationError" class="box error">
        <h2 class="mb-4 text-center">
          {{ $t('communication-error') }}
        </h2>
        {{ $t('core-cannot-be-reached') }}
      </div>

      <Login v-else-if="!currentUser" />

      <template v-else>
        <CytomineNavbar />
        <div class="flex-grow overflow-auto">
          <KeepAlive include="CytomineStorage">
            <RouterView v-if="currentUser" />
          </KeepAlive>
        </div>
      </template>
    </template>
  </main>
</template>

<script>
import ifvisible from 'ifvisible';
import { Cytomine } from 'cytomine-client';

import CytomineNavbar from './components/navbar/CytomineNavbar.vue';
import Login from './components/user/Login.vue';
import { changeLanguageMixin } from '@/lang.js';

import constants from '@/utils/constants.js';

ifvisible.setIdleDuration(constants.IDLE_DURATION);

export default {
  name: 'App',
  components: {
    CytomineNavbar,
    Login,
  },
  mixins: [changeLanguageMixin],
  data() {
    return {
      communicationError: false,
      loading: true,
      timeout: null,
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
  },
  created() {
    /**
     * Required to access $route.query in combination with async route loading
     *
     * @see https://router.vuejs.org/api/#router-onready
     */
    this.$router.onReady(async () => {
      // let Settings;
      // await axios
      //   .get('configuration.json')
      //   .then(response => (Settings = response.data));

      // for(var i in constants){
      //   if(Settings.hasOwnProperty(i)) {
      //     constants[i] = Settings[i];
      //   }
      // }
      Object.freeze(constants);

      new Cytomine(constants.CYTOMINE_CORE_HOST);

      if (this.$route.query.token && this.$route.query.username) {
        await this.loginWithToken();
      }
      await this.ping();
      this.loading = false;
      ifvisible.on('wakeup', this.ping);
    });
  },
  methods: {
    async loginWithToken() {
      try {
        await Cytomine.instance.loginWithToken(
          this.$route.query.username,
          this.$route.query.token
        );
        await this.fetchUser();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('invalid-token'),
        });
      }
    },
    async ping() {
      if (!ifvisible.now()) {
        return; // window not visible or inactive user => stop pinging
      }
      try {
        const { authenticated } = await Cytomine.instance.ping(
          this.project ? this.project.id : null
        );
        if (this.currentUser && !authenticated) {
          await this.$store.dispatch('logout');
        }
        if (!this.currentUser && authenticated) {
          await this.fetchUser();
        }
        this.communicationError = false;
      } catch (error) {
        console.log(error);
        this.communicationError = true;
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.ping, constants.PING_INTERVAL);
    },
    async fetchUser() {
      await this.$store.dispatch('currentUser/fetchUser');
      if (this.currentUser) {
        this.changeLanguage(this.currentUser.language);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

html,
body {
  height: 100vh;
  overflow: hidden;
}

.box.error {
  max-width: 600px;
  margin: auto;
  margin-top: 3rem;
}

h1 {
  font-size: 1.25rem;
}

.in-project {
  color: grey;
  font-size: 0.8em;
}

.input[readonly] {
  background-color: whitesmoke;
}

.label {
  font-weight: 600 !important;
}

.content-wrapper {
  padding: 1.5% 2.5%;
  position: relative;
  min-height: 100%;
}

/* Filters */

.filters {
  background: #f8f8f8;
  margin-top: 1.2rem;
  border-radius: 10px;
  padding: 1rem;
}

.filter-label {
  text-transform: uppercase;
  font-size: 0.8em;
  margin-bottom: 0.5em;
  margin-left: 1em;
}

.nb-active-filters {
  display: inline-block;
  background: $primary;
  color: $primary-invert;
  min-width: 1.25rem;
  height: 1.25rem;
  font-weight: 600;
  border-radius: 0.625rem;
  margin-left: 0.5em;
  font-size: 0.9em;
  line-height: 1.25em;
  padding: 0 0.25em;
  position: absolute;
  top: -0.3em;
  right: -0.6em;
}

/* idx color scheme */

a {
  color: $primary;
}

.vue-notification.flex {
  display: flex;
}

.notification-container {
  z-index: 999999;
}
</style>
