<template>
  <CytomineModalCard :title="$t('add-attached-file')">
    <form id="attached-file-form" @submit.prevent="save">
      <!-- Cytomine API uses key `files[]` -->
      <BUpload
        v-model="selectedFile"
        type="is-link"
        drag-drop
        class="block mb-3"
        name="files[]"
        required
      >
        <div class="content px-5 py-6 text-center">
          <template v-if="!selectedFile">
            <i class="fas fa-upload fa-3x mb-2" />
            <p>{{ $t('choose-file-or-drop') }}</p>
          </template>
          <template v-else>
            <p class="is-size-4">
              <i class="fas fa-file" /> {{ selectedFile.name }}
            </p>
            <p class="has-text-grey is-size-6">
              {{ $t('click-or-drop-new-file') }}
            </p>
          </template>
        </div>
      </BUpload>

      <BField
        :label="$t('name')"
        :type="{ 'is-danger': errors.has('name') }"
        :message="errors.first('name')"
      >
        <BInput
          v-model="name"
          :disabled="!selectedFile"
          name="filename"
          required
        />
      </BField>
    </form>

    <template #footer>
      <IdxBtn @click="$parent.close()">
        {{ $t('cancel') }}
      </IdxBtn>
      <IdxBtn
        :disabled="!selectedFile || errors.any()"
        color="blue"
        form="attached-file-form"
        type="submit"
      >
        {{ $t('save') }}
      </IdxBtn>
    </template>
  </CytomineModalCard>
</template>

<script>
import noteApi from '@/services/noteApi.js';
import CytomineModalCard from '@/components/utils/CytomineModalCard.vue';

export default {
  name: 'AttachedFileModal',
  components: { CytomineModalCard },
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    object: { type: Object, required: true },
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      selectedFile: null,
      name: '',
    };
  },
  watch: {
    selectedFile(file) {
      if (file) {
        this.name = file.name;
      }
    },
  },
  methods: {
    async save({ target }) {
      const result = await this.$validator.validateAll('password');
      if (!this.selectedFile || !result) {
        return;
      }

      try {
        const object = this.object;
        const formData = new FormData(target);
        formData.set('domainClassName', object.class);
        formData.set('domainIdent', String(object.id));
        const attached = await noteApi.post('/api/attachedfile.json', {
          body: formData,
        });

        this.$emit('addAttachedFile', attached);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-attached-file-creation'),
        });
        this.$parent.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-attached-file-creation'),
        });
      }
    },
  },
};
</script>
