<template>
  <span :class="tagClass" class="tag">
    {{ $t(labels[status].label) }}
  </span>
</template>

<script>
import { JobStatus } from 'cytomine-client';
import jobStatusMapping from '@/utils/job-utils';

export default {
  name: 'JobStatus',
  props: {
    status: Number,
  },
  computed: {
    labels() {
      return jobStatusMapping;
    },
    result() {
      switch (this.status) {
        case JobStatus.KILLED:
          return 'dark';
        case JobStatus.RUNNING:
          return 'info';
        case JobStatus.SUCCESS:
          return 'success';
        case JobStatus.FAILED:
          return 'danger';
        default:
          return 'light';
      }
    },
    tagClass() {
      return 'is-' + this.result;
    },
  },
};
</script>
