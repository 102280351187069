import { pick } from 'vuelayers/src/util/minilo.js';
import Interaction from './interaction.vue';

/**
 * @param Vue
 * @param options
 */
function plugin(Vue, options = {}) {
  if (plugin.installed) {
    return;
  }
  plugin.installed = true;

  options = pick(options, 'dataProjection');
  Object.assign(Interaction, options);

  Vue.component(Interaction.name, Interaction);
}

export default plugin;

export { Interaction, plugin as install };
