<template>
  <div class="properties-wrapper">
    <em v-if="error" class="mr-2">{{ $t('error-fetch-properties') }}</em>
    <div v-else>
      <BTaglist
        v-for="(prop, index) in properties"
        :key="prop.id"
        attached
        class="m-0 pb-1"
      >
        <template v-if="isEditing(prop.id)">
          <form
            :key="prop.id"
            class="new-prop-form flex gap-8 mt-2"
            @submit.prevent="saveProp(getEditingProp(prop), index)"
          >
            <BInput
              v-model="getEditingProp(prop).key"
              size="is-small"
              :placeholder="$t('key')"
            />
            <BInput
              v-model="getEditingProp(prop).value"
              :placeholder="$t('value')"
              size="is-small"
            />
            <IdxBtn small type="submit">
              {{ prop.id ? $t('save') : $t('add') }}
            </IdxBtn>
            <IdxBtn small @click="cancelPropEdition(prop, index)">
              {{ $t('cancel') }}
            </IdxBtn>
          </form>
        </template>
        <template v-else>
          <BTag type="is-dark" class="m-0">
            {{ prop.key }}
          </BTag>
          <BTag class="m-0">
            {{ prop.value }}
            <span v-if="canEdit" class="edit-buttons ml-3">
              <!-- class="edit is-small" -->
              <button
                :title="$t('edit')"
                class="border-0 p-0 color-gray-4"
                @click="startPropEdition(prop)"
              >
                <BIcon icon="edit" size="is-small" class="ml-1" />
                <span class="visually-hidden">{{
                  `${$t('edit')} ${prop.key}`
                }}</span>
              </button>
              <button
                :title="$t('remove')"
                class="border-0 p-0 color-gray-4"
                @click="removeProp(prop)"
              >
                <BIcon icon="times-circle" size="is-small" class="m-0" />
                <span class="visually-hidden">{{
                  `${$t('remove')} ${prop.key}`
                }}</span>
              </button>
            </span>
          </BTag>
        </template>
      </BTaglist>

      <IdxBtn
        v-if="canEdit"
        key="showForm"
        small
        class="add-prop"
        @click="addNewProp"
      >
        {{ $t('add') }}
      </IdxBtn>
    </div>
  </div>
</template>

<script>
import { PropertyCollection } from 'cytomine-client';
import noteApi from '@/services/noteApi.js';
import constants from '@/utils/constants.js';

export default {
  name: 'CytomineProperties',
  props: {
    /** @type {import('vue').PropOptions<{ image: number, properties: array }>} */
    object: { type: Object, required: true },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: false,

      properties: [],
      editedProperties: [],
      newPropKey: '',
      newPropValue: '',
      showNewPropForm: false,
    };
  },
  computed: {
    /** @returns {Array} */
    editedPropertyIds() {
      return this.editedProperties.map((prop) => prop.id);
    },
  },
  async created() {
    if (!this.object.properties) {
      const data = await PropertyCollection.fetchAll({
        object: this.object,
      });
      this.properties = data._data;
    } else {
      this.properties = this.object.properties;
    } // filter the properties used internally
    this.properties = this.properties.reduce((finalProps, prop) => {
      if (!prop.key.startsWith(constants.PREFIX_HIDDEN_PROPERTY_KEY)) {
        if (this.isNumeric(prop.value)) {
          prop.value = Math.round(prop.value * 100) / 100; // round to 2 decimal point
        }
        finalProps.push(prop);
      }
      return finalProps;
    }, []);
  },
  methods: {
    isNumeric(str) {
      if (typeof str != 'string') return false; // we only process strings!
      return (
        !Number.isNaN(str) && !Number.isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    }, // https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
    isEditing(propertyId) {
      return this.editedPropertyIds.includes(propertyId);
    },
    getEditingProp(property) {
      return property.id
        ? this.editedProperties.find((prop) => prop.id === property.id)
        : property;
    },
    addNewProp() {
      this.editedProperties.push({
        oldKey: '',
        oldValue: null,
      });
      this.properties.push({
        key: '',
        value: null,
      });
    },
    async removeProp(property) {
      const object = this.object;
      try {
        await noteApi.delete(
          `/api/domain/${object.class}/${object.id}/property/${property.id}.json`
        );
        this.properties = this.properties.filter(
          (prop) => prop.id !== property.id
        );
        this.$emit('update');
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-remove-prop'),
        });
      }
    },
    startPropEdition(property) {
      // store current values of key and value, so that they can be restored if needed (e.g. edit cancellation)
      const editingProp = {
        ...property,
        oldKey: property.key,
        oldValue: property.value,
      };
      this.editedProperties.push(editingProp); // insert at first position
    },
    async saveProp(property, index) {
      // prevent user from saving a property whose key starts with the internal prefix (would be hidden aftewards)
      if (property.key.startsWith(constants.PREFIX_HIDDEN_PROPERTY_KEY)) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-invalid-key-prefix', {
            prefix: constants.PREFIX_HIDDEN_PROPERTY_KEY,
          }),
        });
        return;
      }

      const object = this.object;
      const requestJson = {
        ...property,
        domainClassName: object.class,
        domainIdent: object.id,
      };
      let response;

      try {
        // check if this is a new property or modifing an existing one
        if (property.id) {
          response = await noteApi.put(
            `/api/domain/${object.class}/${object.id}/property/${property.id}.json`,
            {
              json: requestJson,
            }
          );
        } else {
          response = await noteApi.post(
            `/api/domain/${object.class}/${object.id}/property.json`,
            {
              json: requestJson,
            }
          );
        }
        this.editedProperties = this.editedProperties.filter(
          (prop) => prop.oldKey !== property.oldKey
        );

        if (!property.id) {
          this.properties.splice(index, 1, response.property);
        } else {
          this.properties[index] = response.property;
        }
        this.$emit('update');
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-save-prop'),
        });
      }
    },
    cancelPropEdition(property, propIndex) {
      if (property.id) {
        const editedIndex = this.editedProperties.findIndex(
          (prop) => prop.oldKey === property.key
        );
        this.editedProperties.splice(editedIndex, 1);
      } else {
        const editedIndex = this.editedProperties.findIndex(
          (prop) => prop.oldKey === property.key
        );
        this.editedProperties.splice(editedIndex, 1);
        this.properties.splice(propIndex, 1);
      }
    },
  },
};
</script>

<style>
.properties-wrapper .tag {
  background-color: rgba(0, 0, 0, 0.04);
}

.properties-wrapper .tag.is-dark {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
}

.properties-wrapper .edit-buttons button {
  background-color: transparent;
}
</style>
