import Vue from 'vue';
import VueRouter from 'vue-router';

// Import Components
import GlobalDashboard from './components/GlobalDashboard.vue';
import ListImages from './components/image/ListImages.vue';
import ImageInformation from './components/image/ImageInformation.vue';
import ListJobs from './components/job/ListJobs.vue';

Vue.use(VueRouter);

// Define routes
const routes = [
  {
    path: '/',
    component: GlobalDashboard, // Alternative: component: require("./components/GlobalDashboard.vue").default
  },
  {
    path: '/storage',
    component: () => import('./components/storage/CytomineStorage.vue'),
  },
  {
    path: '/ontology/:idOntology?',
    component: () => import('./components/ontology/ListOntologies.vue'),
  },
  {
    path: '/software',
    component: () =>
      import(
        /* webpackChunkName: "software" */ './components/software/DigitalAssays.vue'
      ),
  },
  {
    path: '/software/:idSoftware',
    component: () =>
      import(
        /* webpackChunkName: "software" */ './components/software/SoftwareInformation.vue'
      ),
  },
  {
    path: '/advanced-search/:searchString?',
    component: () => import('./components/search/AdvancedSearch.vue'),
  },
  {
    path: '/account',
    component: () =>
      import(/* webpackChunkName: 'account' */ './components/user/Account.vue'),
  },
  {
    path: '/account/invite-user',
    component: () =>
      import(
        /* webpackChunkName: 'account' */ './components/user/InviteUser.vue'
      ),
  },
  {
    path: '/project/:idProject',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: 'project' */ './components/project/CytomineProject.vue'
      ),
    children: [
      {
        path: '',
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/project/ProjectHome.vue'
          ),
      },
      {
        path: 'images',
        props: true,
        component: ListImages,
      },
      {
        path: 'image/:idImages',
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/viewer/CytomineViewer.vue'
          ),
      },
      {
        path: 'image/:idImage/information',
        component: ImageInformation,
      },
      {
        path: 'image/:idImages/annotation/:idAnnotation',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/viewer/CytomineViewer.vue'
          ),
      },
      {
        path: 'annotations',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/annotations/ListAnnotations.vue'
          ),
      },
      {
        path: 'analysis',
        component: ListJobs,
      },
      {
        path: 'activity',
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/project/ProjectActivity.vue'
          ),
      },
      {
        path: 'activity/user/:idUser',
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/project/activity/MemberActivityDetails.vue'
          ),
      },
      {
        path: 'information',
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/project/ProjectInformation.vue'
          ),
      },
      {
        path: 'results',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/project/ProjectResults.vue'
          ),
      },
      {
        path: 'visualizations',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/project/visualizations/ProjectVisualizations.vue'
          ),
      },
      {
        path: 'configuration',
        component: () =>
          import(
            /* webpackChunkName: 'project' */ './components/project/ProjectConfiguration.vue'
          ),
      },
    ],
  },
  {
    path: '/activity',
    component: () => import('./components/user/UserActivity.vue'),
  },
  {
    path: '/admin',
    component: () => import('./components/admin/AdminPanel.vue'),
  },

  // redirections for old URLS
  {
    path: '/userdashboard',
    redirect: '/',
  },
  {
    path: '/project',
    redirect: '/projects',
  },
  {
    path: '/explorer',
    redirect: '/',
  },
  {
    path: '/upload',
    redirect: '/storage',
  },

  {
    path: '/activity',
    redirect: '/',
  },
  {
    path: '/activity-:idProject-',
    redirect: '/project/:idProject/activity',
  },
  {
    path: '/activity-:idProject-:idUser',
    redirect: '/project/:idProject/activity/user/:idUser',
  },

  {
    path: '/search-',
    redirect: '/advanced-search',
  },

  {
    path: '/admin-tabs-dashboard',
    redirect: '/admin?tab=dashboard',
  },
  {
    path: '/admin-tabs-users',
    redirect: '/admin?tab=users',
  },
  {
    path: '/admin-tabs-groups',
    redirect: '/',
  },
  {
    path: '/admin-tabs-permissions',
    redirect: '/',
  },
  {
    path: '/admin-tabs-configuration',
    redirect: '/admin?tab=configuration',
  },

  {
    path: '/tabs-dashboard-:idProject',
    redirect: '/project/:idProject/information',
  },
  {
    path: '/tabs-images-:idProject',
    redirect: '/project/:idProject/images',
  },
  {
    path: '/tabs-annotations-:idProject',
    redirect: '/project/:idProject/annotations',
  },
  {
    path: '/tabs-annotationproperties-:idProject-:idAnnot',
    redirect: '/project/:idProject',
  },
  {
    path: '/tabs-imageproperties-:idProject-:idImage',
    redirect: '/project/:idProject',
  },
  {
    path: '/tabs-imageproperties-:idProject-:idImage',
    redirect: '/project/:idProject',
  },
  {
    path: '/tabs-algos-:idProject',
    redirect: '/project/:idProject/analysis',
  },
  {
    path: '/tabs-visualizations-:idProject',
    redirect: '/project/:idProject/visualizations',
  },
  {
    path: '/tabs-config-:idProject',
    redirect: '/project/:idProject/configuration',
  },
  {
    path: '/tabs-usersconfig-:idProject',
    redirect: '/project/:idProject/configuration?tab=members',
  },
  {
    path: '/tabs-#tabs-useractivity-:idProject-:idUser',
    redirect: '/project/:idProject/activity/user/:idUser',
  },
  {
    path: '/tabs-image-:idProject-:idImage-0',
    redirect: '/project/:idProject/image/:idImage',
  },
  {
    path: '/tabs-image-:idProject-:idImage-:idAnnotation',
    redirect: '/project/:idProject/image/:idImage/annotation/:idAnnotation',
  },
  {
    path: '/tabs-image-:idProject-:idImage-',
    redirect: '/project/:idProject/image/:idImage',
  },
  // -----

  {
    path: '*',
    component: () => import('./components/PageNotFound.vue'),
  },
];

// Create router instance
const router = new VueRouter({
  routes: routes,
  linkActiveClass: 'is-active',
});

export default router;
