// Description: Service for communicating with the training framework microservice
// Based on documentation here: https://docs.google.com/document/d/1ey9ckfLuqo68AcufmXXL8LY6ObvJqBY4sOP5r0SQRyo/edit#heading=h.iovd9q39i94x

import constants from '@/utils/constants.js';
import http from '@/utils/http.js';

const trainingFramework = http.create({
  baseURL: constants.TRAINING_FRAMEWORK_BASE_URL,
  headers: {
    Authorization: `Bearer ${constants.TRAINING_FRAMEWORK_TOKEN}`,
  },
  credentials: 'include',
  modifyResponse: (r) => r.data,
});

/**
 * Runs a training job
 *
 * @param projectId
 * @param model
 * @param config
 * @param tags
 * @param userId
 * @param userIds
 * @param imageIds
 * @param termIds
 * @param termNames
 * @param parameters
 * @param title
 * @param environment
 * @param startWeights
 * @returns {Promise<{pid: number}>}}
 */
export const RunTraining = (
  projectId,
  model,
  config,
  tags,
  userId,
  userIds,
  imageIds,
  termNames,
  parameters,
  title,
  environment,
  startWeights = null
) => {
  return trainingFramework.post(`api/training/run`, {
    json: {
      project_id: projectId,
      model,
      config,
      tags,
      started_by: userId,
      user_ids: userIds,
      images: imageIds,
      terms: termNames,
      parameters,
      title,
      dataset_split: 'manual',
      environment,
      start_weights: startWeights,
    },
  });
};

/**
 * Gets the status of a training job
 *
 * @param {number} pid
 * @returns Promise<{valohai_job_status: String, metadata: Array}>
 */
export const GetTrainingStatus = (pid) => {
  return trainingFramework.get(`/api/details/${pid}`);
};

/**
 * Gets the training configurations for a model
 *
 * @param {string} modelName
 * @returns Promise<{configuration_name: String, parameters: Object, inputs: Object}>
 */
export const GetTrainingConfigurations = (modelName) => {
  return trainingFramework.get(
    `/api/training/configurations/${modelName.toLowerCase()}`
  );
};

/**
 * Gets the available models
 *
 * @param {string} modelName
 * @returns Promise<{configuration_name: String, parameters: Object, inputs: Object}>
 */
export const GetTrainingModels = () => {
  return trainingFramework.get(`/api/training/models`);
};

export const GetOutputs = (projectId) => {
  return trainingFramework.get(`/api/training/${projectId}`);
};
