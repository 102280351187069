<template>
  <VAsync v-slot="promise" :await="request">
    <IdxBtn
      v-if="property.value === 'pass'"
      class="uppercase"
      :disabled="promise.pending"
      color="success"
      small
      @click="toggle"
    >
      {{ $t('pass') }}
    </IdxBtn>
    <IdxBtn
      v-else-if="property.value === 'fail'"
      class="uppercase"
      :disabled="promise.pending"
      color="danger"
      small
      @click="toggle"
    >
      {{ $t('fail') }}
    </IdxBtn>
    <IdxBtn
      v-else
      class="uppercase"
      :disabled="promise.pending"
      color="warning"
      small
      @click="toggle"
    >
      {{ $t('review') }}
    </IdxBtn>
  </VAsync>
</template>

<script>
import noteApi from '@/services/noteApi.js';

export default {
  name: 'QcToggle',
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance & { properties: Array<{ id: number, key: string, value: any}>}>} */
    image: { type: Object, required: true },
  },
  data() {
    return {
      property: {
        value: 'review',
      },
      request: null,
    };
  },
  created() {
    const qcProperty = this.image.properties.find(
      (prop) => prop.key.toLowerCase() === '@qc'
    );
    if (qcProperty) {
      this.property = qcProperty;
    }
  },
  methods: {
    async toggle() {
      const { image, property } = this;
      let qcStatus = property.value;
      const oldValue = qcStatus;

      switch (qcStatus) {
        case 'pass':
          qcStatus = 'fail';
          break;
        case 'fail':
          qcStatus = 'review';
          break;
        default:
          qcStatus = 'pass';
      }

      try {
        const json = {
          domainClassName: image.class,
          domainIdent: image.id,
          key: '@qc',
          value: qcStatus,
        };

        // check if this is a new property or modifing an existing one
        if (property.id) {
          json.id = property.id;

          this.request = noteApi.put(
            `/api/domain/${image.class}/${image.id}/property/${property.id}.json`,
            {
              json,
            }
          );
        } else {
          this.request = noteApi.post(
            `/api/domain/${image.class}/${image.id}/property.json`,
            {
              json,
            }
          );
        }
        const response = await this.request;
        this.property = response.property;

        this.$emit('update');
      } catch (error) {
        this.property.value = oldValue;
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-save-prop'),
        });
      }
    },
  },
};
</script>

<style scoped>
.button {
  width: 4rem;
}
</style>
