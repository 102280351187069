<template>
  <div class="panel">
    <div v-if="$slots.header || header" class="radius-t-8 p-4 bg-gray-1">
      <slot v-if="$slots.header" name="header" />

      <component :is="headerTag" v-else-if="header" class="size-20">
        {{ header }}
      </component>
    </div>

    <div class="p-4">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdxPanel',
  props: {
    header: {
      type: String,
      default: '',
    },
    headerTag: {
      type: String,
      default: 'p',
    },
  },
};
</script>
