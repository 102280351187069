<template>
  <div class="flex flex-wrap align-center">
    <template v-if="loading" />
    <em v-else-if="error">{{ $t('error-attached-files') }}</em>
    <template v-else>
      <span v-for="(file, index) in attachedFiles" :key="file.id" class="mr-2">
        <a :href="host + file.url" class="mr-1">{{ file.filename }}</a>
        <button
          v-if="canEdit"
          :title="$t('remove')"
          class="border-0 p-0 color-gray-4 bg-transparent"
          @click="confirmDeletion(file, index)"
        >
          <BIcon icon="times-circle" size="is-small" class="m-0" />
          <span class="visually-hidden">{{
            `${$t('remove')} ${file.filename}`
          }}</span>
        </button>
        <template v-if="index < attachedFiles.length - 1">,</template>
      </span>
      <IdxBtn v-if="canEdit" small @click="displayModal">
        {{ $t('add') }}
      </IdxBtn>
    </template>
  </div>
</template>

<script>
import AttachedFileModal from './AttachedFileModal.vue';
import constants from '@/utils/constants.js';
import noteApi from '@/services/noteApi.js';

export default {
  name: 'AttachedFiles',
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    object: { type: Object, required: true },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      attachedFiles: [],
    };
  },
  computed: {
    /** @returns {string} */
    host() {
      return constants.CYTOMINE_CORE_HOST;
    },
  },
  async created() {
    try {
      const object = this.object;
      const response = await noteApi.get(
        `/api/domain/${object.class}/${object.id}/attachedfile.json?max=0&offset=0`
      );
      this.attachedFiles = response.collection;
    } catch (error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  },
  methods: {
    displayModal() {
      // required to use programmatic modal because the description is sometimes displayed in elements with a
      // CSS transform (e.g. popover) that conflict with the fixed position of the modal
      // (http://meyerweb.com/eric/thoughts/2011/09/12/un-fixing-fixed-elements-with-css-transforms/)

      this.$buefy.modal.open({
        parent: this,
        component: AttachedFileModal,
        props: { object: this.object },
        hasModalCard: true,
        events: { addAttachedFile: this.addAttachedFile },
      });
    },
    addAttachedFile(attachedFile) {
      this.attachedFiles.push(attachedFile);
    },
    confirmDeletion(attachedFile, idx) {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-attached-file', {
          filename: attachedFile.filename,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteAttachedFile(attachedFile, idx),
      });
    },
    async deleteAttachedFile(attachedFile, idx) {
      try {
        await noteApi.delete(`/api/attachedfile/${attachedFile.id}.json`);
        this.attachedFiles.splice(idx, 1);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-attached-file-deletion', {
            filename: attachedFile.filename,
          }),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-attached-file-deletion', {
            filename: attachedFile.filename,
          }),
        });
      }
    },
  },
};
</script>
