import {
  Cytomine,
  Project,
  ProjectConnection,
  AnnotationType,
  UserCollection,
  ProjectMemberRole,
} from 'cytomine-client';
import noteApi from '../../services/noteApi.js';
import { fullName } from '@/utils/user-utils.js';

function getDefaultState() {
  return {
    project: null,
    configUI: {},
    ontology: null,
    managers: [],
    members: [],
    contributors: [],
    representatives: [],
    currentViewer: null,
  };
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    setProject(state, project) {
      state.project = project;
    },

    setOntology(state, ontology) {
      state.ontology = ontology;
    },

    setConfigUI(state, config) {
      state.configUI = config;
    },

    setManagers(state, managers) {
      state.managers = managers;
    },

    setMembers(state, members) {
      state.members = members;
    },

    setContributors(state, contributors) {
      state.contributors = contributors;
    },

    setRepresentatives(state, representatives) {
      state.representatives = representatives;
    },

    setCurrentViewer(state, id) {
      state.currentViewer = id;
    },
  },

  actions: {
    async loadProject({ state, dispatch, commit }, idProject) {
      const projectChange = !state.project || state.project.id !== idProject;
      const project = await Project.fetch(idProject);
      commit('setProject', project);
      commit(`projects/${project.id}/setProject`, project, { root: true });

      const promises = [
        dispatch('fetchUIConfig'),
        dispatch('fetchOntology'),
        dispatch('fetchProjectMembers'),
      ];

      if (projectChange) {
        promises.push(new ProjectConnection({ project: idProject }).save());
      }
      await Promise.all(promises);
    },

    async updateProject({ state, dispatch, commit }, updatedProject) {
      const reloadOntology = state.project.ontology !== updatedProject.ontology;
      commit('setProject', updatedProject);
      commit(`projects/${updatedProject.id}/setProject`, updatedProject, {
        root: true,
      });
      if (reloadOntology) {
        await dispatch('fetchOntology');
      }
    },

    async fetchUIConfig({ state, commit }) {
      const config = await Cytomine.instance.fetchUIConfigCurrentUser(
        state.project.id
      );
      commit('setConfigUI', config);
    },

    async fetchProjectMembers({ state, commit }) {
      const collection = new UserCollection({
        filterKey: 'project',
        filterValue: state.project.id,
      });

      const members = (await collection.fetchAll()).array;
      const managers = members.filter(
        (u) =>
          u.role == ProjectMemberRole.MANAGER ||
          u.role == ProjectMemberRole.REPRESENTATIVE
      );

      const representatives = members.filter(
        (u) => u.role == ProjectMemberRole.REPRESENTATIVE
      );
      const idsManagers = managers.map((user) => user.id);
      const contributors = members.filter(
        (user) => !idsManagers.includes(user.id)
      );

      members.forEach((member) => (member.fullName = fullName(member)));

      commit('setManagers', managers);
      commit('setMembers', members);
      commit('setRepresentatives', representatives);
      commit('setContributors', contributors);
    },

    async fetchOntology({ state, commit }) {
      const ontologyId = state.project.ontology;
      let ontology = null;
      if (ontologyId) {
        ontology = await noteApi.get(`/napi/ontology/${ontologyId}`);
      }
      commit('setOntology', ontology);
    },
  },

  getters: {
    canEditLayer: (state, getters, rootState) => (idLayer) => {
      const currentUser = rootState.currentUser.user;
      const project = state.project;
      return (
        getters.canManageProject ||
        (!currentUser.guestByNow &&
          !project.isReadOnly &&
          (idLayer === currentUser.id || !project.isRestricted))
      );
    },

    canEditAnnot: (_, getters, rootState) => (annot) => {
      const currentUser = rootState.currentUser.user;
      const idLayer = annot.user;
      if (annot.type === AnnotationType.REVIEWED) {
        return (
          currentUser.admin ||
          (!currentUser.guestByNow && annot.reviewUser === currentUser.id)
        );
      }
      return getters.canEditLayer(idLayer);
    },

    canEditImage: (state, getters, rootState) => (image) => {
      const currentUser = rootState.currentUser.user;
      const project = state.project;
      return (
        getters.canManageProject ||
        (!currentUser.guestByNow &&
          !project.isReadOnly &&
          (image.user === currentUser.id || !project.isRestricted))
      );
    },

    canManageJob: (state, getters, rootState) => (job) => {
      const currentUser = rootState.currentUser.user;
      const project = state.project;
      return (
        getters.canManageProject ||
        (!currentUser.guestByNow &&
          !project.isReadOnly &&
          (job.username === currentUser.username || !project.isRestricted))
      );
    },

    isManager: (state, _, rootState) => {
      // true if current user is project manager
      const currentUser = rootState.currentUser.user || {};
      return state.managers.some((user) => user.id === currentUser.id);
    },

    canManageProject: (state, _, rootState) => {
      // true iff current user is admin or superadmin or project manager
      const currentUser = rootState.currentUser.user || {};
      return (
        currentUser.admin ||
        (!currentUser.guestByNow &&
          state.managers.some((user) => user.id === currentUser.id))
      );
    },

    isRepresentative: (state, _, rootState) => {
      // true iff current user is project representative
      const currentUser = rootState.currentUser.user || {};
      return (
        !currentUser.guestByNow &&
        state.representatives.some((user) => user.id === currentUser.id)
      );
    },

    isContributor: (state, _, rootState) => {
      // true iff current user is contributor
      const currentUser = rootState.currentUser.user || {};
      return (
        !currentUser.guestByNow &&
        state.contributors.some((user) => user.id === currentUser.id)
      );
    },

    contributors: (state) => {
      const idsManagers = state.managers.map((user) => user.id);
      return state.members.filter((user) => !idsManagers.includes(user.id));
    },

    terms: (state) => {
      return state.ontology ? state.ontology.terms : null;
    },

    currentProjectModule: (state) => {
      if (!state.project) {
        return null;
      }
      return `projects/${state.project.id}/`;
    },

    currentViewer: (state, _, rootState) => {
      if (!state.project) {
        return null;
      }
      return rootState.projects[state.project.id].viewers[state.currentViewer];
    },

    currentViewerModule: (state, getters) => {
      return `${getters.currentProjectModule}viewers/${state.currentViewer}/`;
    },

    imageModule: (_, getters) => (index) => {
      return `${getters.currentViewerModule}images/${index}/`;
    },
  },
};
