import Vue from 'vue';
import Vuex from 'vuex';

import currentUser from './modules/current-user.js';
import currentProject from './modules/current-project.js';
import ontologies from './modules/ontologies.js';
import listProjects from './modules/list-projects.js';
import listSoftware from './modules/list-software.js';
import projectVisualizations from './modules/project-visualizations.js';

Vue.use(Vuex);
const store = new Vuex.Store({
  actions: {
    logout({ state, commit }) {
      commit('currentUser/resetState');
      commit('currentProject/resetState');
      commit('ontologies/resetState');
      commit('listProjects/resetState');
      commit('listSoftware/resetState');
      commit('projectVisualizations/resetState');
      for (const key in state.projects) {
        this.unregisterModule(['projects', key]);
      }
    },
  },
  modules: {
    currentUser,
    currentProject,
    ontologies,
    listProjects,
    listSoftware,
    projectVisualizations,
    projects: {
      namespaced: true,
    },
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;

/**
 * @param state
 */
export function getModuleNamespace(state) {
  // to update if https://github.com/vuejs/vuex/issues/1244 is implemented
  const pathes = Object.keys(store._modulesNamespaceMap);
  const moduleNamespace = pathes.find(
    (path) => store._modulesNamespaceMap[path].context.state === state
  );
  if (typeof moduleNamespace === 'string') {
    return moduleNamespace.slice(0, -1).split('/');
  }
}
