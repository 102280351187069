<template>
  <CytomineModalCard
    :title="$t('associate-tags')"
    active
    class="add-tag-domain-modal"
  >
    <BLoading :is-full-page="false" :active="loading" class="small" />
    <DomainTagInput
      v-if="!loading"
      v-model="selectedTags"
      :domains="notAssociatedTags"
      placeholder="search-or-create-tag"
      allow-new
    />

    <template #footer>
      <IdxBtn @click="$parent.close">
        {{ $t('cancel') }}
      </IdxBtn>
      <IdxBtn color="blue" @click="addAssociations">
        {{ $t('add') }}
      </IdxBtn>
    </template>
  </CytomineModalCard>
</template>

<script>
import noteApi from '@/services/noteApi.js';
import DomainTagInput from '@/components/utils/DomainTagInput.vue';
import CytomineModalCard from '@/components/utils/CytomineModalCard.vue';

export default {
  name: 'AddTagModal',
  components: {
    CytomineModalCard,
    DomainTagInput,
  },
  props: {
    /** @type {import('vue').PropOptions<Array>} */
    associatedTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      tags: [],
      selectedTags: [],
    };
  },
  computed: {
    /** @returns {CytoTag[]} */
    notAssociatedTags() {
      const associatedTagIds = this.associatedTags.map((u) => u.tag);
      return this.tags.filter((tag) => !associatedTagIds.includes(tag.id));
    },
  },
  async created() {
    this.loading = true;
    const response = await noteApi.get(`/api/tag.json?max=0&offset=0`);
    this.tags = response.collection;
    this.loading = false;
  },
  methods: {
    async addAssociations() {
      this.$emit('addObjects', this.selectedTags);
      this.$parent.close();
    },
  },
};
</script>

<style scoped>
.add-tag-domain-modal,
>>> .modal-card-body {
  overflow: visible !important;
}
</style>
