import escapeRegExp from 'lodash/escapeRegExp.js';

/**
 * Generates a random string of defined length based on
 * a string of allowed characters.
 *
 * @param  {number} length  How many random characters will be in the returned string. Defaults to 10
 * @param  {string} allowed Which characters can be used when creating the random string. Defaults to A-Z,a-z,0-9
 * @returns {string} A string of random characters
 */
export function randomString(
  length = 10,
  allowed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
) {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += allowed.charAt(Math.floor(Math.random() * allowed.length));
  }
  return result;
}

/**
 * Return a Regexp based on the provided search string, and using * as a wildcard character.
 *
 * @param {string} str The string entered by the user
 * @returns {RegExp}
 */
export function getWildcardRegexp(str) {
  const escapedString = escapeRegExp(str); // escape all RegExp special characters (only * should have a special behaviour)
  return new RegExp('(' + escapedString.split('\\*').join('.*') + ')', 'i');
}

/**
 * @param {string | number} bytes
 * @param {string} [separator='']
 * @param {string} [postFix='']
 * @returns {string}
 */
export function formatBytes(bytes, separator = '', postFix = '') {
  if (!bytes) return 'n/a';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.min(
    parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10),
    sizes.length - 1
  );
  return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${
    sizes[i]
  }${postFix}`;
}

/**
 * @param {string} needle
 * @param {string} haystack
 * @param {string} [delineator=' ']
 * @returns {boolean}
 */
export function fuzzysearch(needle, haystack, delineator = ' ') {
  const pattern = needle
    .trim()
    .split(delineator)
    .join('|');
  const regex = new RegExp(pattern, 'i');
  return regex.test(haystack);
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
