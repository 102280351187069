<template>
  <BLoading v-if="loading" :is-full-page="false" active class="small" />
  <form v-else-if="isEditing" @submit.prevent="saveDescription">
    <IdxInput
      :value="(descriptionObject && descriptionObject.data) || ''"
      :label="$t('description')"
      hide-label
      name="description"
      type="textarea"
      :readonly="preventEdit"
      class="mb-2"
    />

    <div class="flex gap-8">
      <IdxBtn :disabled="preventEdit" small type="submit">
        {{ $t('save') }}
      </IdxBtn>
      <IdxBtn small @click="isEditing = false">
        {{ $t('cancel') }}
      </IdxBtn>
    </div>
  </form>
  <div v-else class="flex gap-8 align-center">
    {{ (descriptionObject && descriptionObject.data) || '' }}
    <IdxBtn v-if="!preventEdit" small @click="isEditing = true">
      {{ $t('edit') }}
    </IdxBtn>
  </div>
</template>

<script>
import noteApi from '@/services/noteApi.js';

// To eventually replace CytomineDescription.vue
export default {
  name: 'IdxDescription',
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    object: { type: Object, required: true },
    preventEdit: Boolean,
  },
  data() {
    return {
      loading: false,
      isEditing: false,
      descriptionObject: null,
    };
  },
  async created() {
    try {
      const object = this.object;
      this.loading = true;
      this.descriptionObject = await noteApi.get(
        `/api/domain/${object.class}/${object.id}/description.json`
      );
    } catch (err) {
      // the error may make sense if the object has no description
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async saveDescription(event) {
      const { descriptionObject, object } = this;
      const data = new FormData(event.target);
      const newDescription = data.get('description');

      if (!descriptionObject && !newDescription) {
        this.isEditing = false;
        return;
      }

      try {
        if (descriptionObject) {
          const response = await noteApi.put(
            `/api/domain/${object.class}/${object.id}/description.json`,
            {
              json: {
                ...descriptionObject,
                data: data.get('description'),
              },
            }
          );
          this.descriptionObject = response.description;
        } else if (newDescription) {
          const response = await noteApi.post(
            `/api/domain/${object.class}/${object.id}/description.json`,
            {
              json: {
                domainClassName: object.class,
                domainIdent: object.id,
                data: data.get('description'),
              },
            }
          );
          this.descriptionObject = response.description;
        } else {
          await noteApi.delete(
            `/api/domain/${object.class}/${object.id}/description.json`
          );
          this.descriptionObject = null;
        }
        this.isEditing = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('unexpected-error-info-message'),
        });
      }
    },
  },
};
</script>
