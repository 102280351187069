import constants from '@/utils/constants.js';
import http from '@/utils/http.js';

const noteApi = http.create({
  baseURL: constants.CYTOMINE_CORE_HOST,
  credentials: 'include',
  modifyResponse: (r) => r.data,
});

export default noteApi;

/**
 * @typedef {{ like: string, ilike: string, gt: number, lt: number, gte: number, lte: number }} QueryParamObject
 */

/**
 * @param {{
 * search?: string
 * page?: number
 * perPage?: number
 * sortBy?: string
 * sortDirection?: 'asc'|'desc'
 * withMembersCount: boolean
 * withLastActivity: boolean
 * withCurrentUserRoles: boolean
 * numberOfImages?: QueryParamObject,
 * membersCount?: QueryParamObject,
 * numberOfAnnotations?: QueryParamObject,
 * numberOfJobAnnotations?: QueryParamObject,
 * numberOfReviewedAnnotations?: QueryParamObject,
 * }} params
 * @returns {Promise<{collection: CytoImageInstance[], size: number}>}
 */
export function getProjects(params) {
  const { search = '', page = 1, perPage = 10 } = params;
  const query = {
    max: perPage,
    offset: Math.max(page - 1, 0) * perPage,
  };
  if (search.trim()) {
    query['name[ilike]'] = encodeURIComponent(search.trim());
  }
  if (params.sortBy) {
    query.sort = params.sortBy;
    query.order = params.sortDirection || 'asc';
  }
  for (const param of [
    'withMembersCount',
    'withLastActivity',
    'withCurrentUserRoles',
  ]) {
    if (params[param]) {
      query[param] = param;
    }
  }
  for (const queryParam in [
    'numberOfImages',
    'membersCount',
    'numberOfAnnotations',
    'numberOfJobAnnotations',
    'numberOfReviewedAnnotations',
  ]) {
    /** @type {QueryParamObject} */
    const queryParamObject = params[queryParam] || {};
    const entries = Object.entries(queryParamObject);
    if (!entries.length) continue;

    for (const [nestedKey, value] of entries) {
      query[`${queryParam}[${nestedKey}]`] = value;
    }
  }
  return noteApi.get(`/napi/project`, { query });
}

/**
 * @param {{
 * projectId: number,
 * search?: string
 * page?: number
 * perPage?: number
 * sortBy?: string
 * sortDirection?: 'asc'|'desc'
 * extensions?: Array<string|number>,
 * mimeTypes?: Array<string|number>,
 * resolutions?: Array<string|number>,
 * tags?: Array<string|number>,
 * numberOfAnnotations?: QueryParamObject,
 * numberOfJobAnnotations?: QueryParamObject,
 * numberOfReviewedAnnotations?: QueryParamObject,
 * }} params
 * @returns {Promise<{collection: CytoImageInstance[], size: number}>}
 */
export function getProjectImages(params) {
  const { search = '', page = 1, perPage = 10 } = params;
  const query = {
    max: perPage,
    offset: Math.max(page - 1, 0) * perPage,
  };
  if (search.trim()) {
    query['name[ilike]'] = encodeURIComponent(search.trim());
  }
  if (params.sortBy) {
    query.sort = params.sortBy;
    query.order = params.sortDirection || 'asc';
  }
  for (const queryParam in [
    'numberOfAnnotations',
    'numberOfJobAnnotations',
    'numberOfReviewedAnnotations',
  ]) {
    /** @type {QueryParamObject} */
    const queryParamObject = params[queryParam] || {};
    const entries = Object.entries(queryParamObject);
    if (!entries.length) continue;

    for (const [nestedKey, value] of entries) {
      query[`${queryParam}[${nestedKey}]`] = value;
    }
  }
  for (const queryParam in ['extensions', 'mimeTypes', 'resolution', 'tag']) {
    /** @type {Array<string|number>} */
    const queryParamArray = params[queryParam] || [];
    if (!queryParamArray.length) continue;

    query[`${queryParam}[in]`] = queryParamArray.join(',');
  }

  return noteApi.get(`/api/project/${params.projectId}/imageinstance.json`, {
    query,
  });
}

/**
 * @param {{
 * search?: string
 * page?: number
 * perPage?: number
 * sortBy?: string
 * sortDirection?: 'asc'|'desc'
 * }} params
 * @returns {Promise<{collection: CytoImageInstance[], size: number}>}
 */
export function getAbstractImages({
  search = '',
  page = 1,
  perPage = 10,
  sortBy = 'id',
  sortDirection = 'asc',
}) {
  const query = {
    max: perPage,
    offset: Math.max(page - 1, 0) * perPage,
  };
  if (search.trim()) {
    query['name[ilike]'] = encodeURIComponent(search.trim());
  }
  if (sortBy) {
    query.sort = sortBy;
    query.order = sortDirection || 'asc';
  }
  return noteApi.get(`/api/abstractimage.json`, {
    query,
  });
}

/**
 * @param {{
 * projectId: number
 * imageId: number
 * annotationIds: Array
 * userIds: Array
 * termIds: Array
 * isHardDelete: boolean
 * }} params
 * @returns {Promise<{collection: CytoImageInstance[], size: number}>}
 */
export function bulkDeleteAnnotations({
  projectId,
  imageId,
  annotationIds = [],
  userIds = [],
  termIds = [],
  isHardDelete = false,
}) {
  const data = {
    projectId,
    imageId,
    hardDelete: isHardDelete,
  };

  if (termIds && termIds.length) {
    data.termIds = termIds;
  }

  if (annotationIds && annotationIds.length) {
    data.annotationIds = annotationIds;
  }

  if (userIds && userIds.length) {
    data.userIds = userIds;
  }

  return noteApi.delete(`/napi/annotation`, {
    json: data,
  });
}

/**
 * @param {{
 * projectId: number
 * imageId: number
 * annotationId: number
 * userIds: Array
 * termIds: Array
 * }} params
 * @returns {Promise<{collection: CytoImageInstance[], size: number}>}
 */
export function reshapeAnnotations({
  projectId,
  imageId,
  annotationId,
  userIds = [],
  termIds = [],
}) {
  const data = {
    projectId,
    imageId,
    annotationId,
  };

  if (termIds && termIds.length) {
    data.termIds = termIds;
  }

  if (userIds && userIds.length) {
    data.userIds = userIds;
  }

  return noteApi.post(`/napi/annotation/reshape`, {
    json: data,
  });
}

/**
 * @param projectId
 * @param {Array<number>} imageIds
 * @returns {Promise}
 */
export function downloadImages(projectId, imageIds) {
  return noteApi.get('/napi/image/download', {
    query: { projectId, imageId: imageIds },
  });
}

/**
 * @param {{
 * projectId: number
 * imageId: number
 * annotationIds: Array
 * }} params
 * @returns {Promise<{collection: CytoImageInstance[], size: number}>}
 */
export function undoBulkDeleteAnnotations({
  projectId,
  imageId,
  annotationIds = [],
}) {
  const data = {
    projectId,
    imageId,
    annotationIds: annotationIds,
  };

  return noteApi.put(`/napi/annotation`, {
    json: data,
  });
}

/**
 * @param {{
 * projectId: number
 * termId: number
 * annotationIds: Array
 * }} params
 * @returns {Promise<{countAffected: number}>}
 */
export function addTermToAnnotations({
  projectId,
  termId,
  annotationIds = [],
}) {
  const data = {
    projectId,
    termId,
    annotationIds,
  };

  return noteApi.post(`/napi/terms`, {
    json: data,
  });
}

/**
 * @param {{
 * projectId: number
 * termId: number
 * annotationIds: Array
 * }} params
 * @returns {Promise<{countAffected: number}>}
 */
export function deleteTermFromAnnotations({
  projectId,
  termId,
  annotationIds = [],
}) {
  const data = {
    projectId,
    termId,
    annotationIds,
  };

  return noteApi.delete(`/napi/terms`, {
    json: data,
  });
}
