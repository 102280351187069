<template>
  <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
    <slot name="error">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </slot>
  </BMessage>
  <BTable
    v-else
    :loading="loading || internalLoading"
    :data="data"
    :columns="columns"
    :total="total || data.length"
    :per-page="perPage"
    :current-page="currentPage"
    :detailed="detailed"
    :detail-key="detailKey"
    :opened-detailed="openedDetailed.slice()"
    :default-sort="sortBy"
    :default-sort-direction="sortDirection"
    :checkable="checkable"
    :checked-rows.sync="internalCheckedRows"
    :custom-is-checked="
      (a, b) => {
        return a.id === b.id;
      }
    "
    :is-row-checkable="isRowCheckable"
    paginated
    backend-pagination
    pagination-size="is-small"
    backend-sorting
    class="idx-table"
    @page-change="onPageChange"
    @update:currentPage="$emit('update:currentPage', $event)"
    @update:openedDetailed="$emit('update:openedDetailed', $event)"
    @sort="updateSort"
  >
    <template #header="{ column }">
      <slot :column="column" name="header" :index="index">
        {{ column.label }}
      </slot>
    </template>

    <slot v-bind="props" name="default" />

    <template #detail="props">
      <slot v-bind="props" name="detail" />
    </template>

    <template v-if="!loading && !internalLoading" #empty>
      <div class="content has-text-grey has-text-centered">
        <slot :row="row" name="empty" :index="index">
          {{ $t('no-result') }}
        </slot>
      </div>
    </template>

    <template #footer>
      <slot name="footer" />
    </template>

    <template #bottom-left>
      <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
      <label class="flex gap-8 align-center">
        <BSelect
          :value="perPage"
          size="is-small"
          @input="$emit('update:perPage', $event)"
        >
          <option
            v-for="option in perPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </BSelect>
        {{ $t('per-page') }}
      </label>
    </template>
  </BTable>
</template>

<script>
export const DEFAULTS = {
  perPage: 25,
};

export default {
  name: 'IdxTable',
  props: {
    /** @type {import('vue').PropOptions<Promise | (() => Promise)>} */
    await: {
      type: [Promise, Function],
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    perPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    perPage: {
      type: Number,
      default: DEFAULTS.perPage,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    detailKey: {
      type: String,
      default: 'id',
    },
    openedDetailed: {
      type: Array,
      default: () => [],
    },
    sortBy: {
      type: String,
      default: 'id',
    },
    sortDirection: {
      type: String,
      default: 'asc',
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    isRowCheckable: {
      type: Function,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    index: 0,
    row: null,

    internalLoading: false,
    error: false,
    internalCheckedRows: [],
  }),
  watch: {
    /** @param {Promise | (() => Promise)} promise */
    await: {
      handler: async function (promise) {
        if (promise == null) return;
        if (typeof promise === 'function') promise = promise();
        this.internalLoading = true;
        await promise.catch((error) => {
          console.error(error);
          this.error = error;
        });
        this.internalLoading = false;
      },
      immediate: true,
    },
    internalCheckedRows(chckedRows) {
      this.$emit('update:checkedRows', chckedRows);
    },
  },
  created() {
    this.internalCheckedRows = this.checkedRows;
  },
  methods: {
    updateSort(sortBy, sortDirection) {
      this.$emit('update:sortBy', sortBy);
      this.$emit('update:sortDirection', sortDirection);
    },
    onPageChange() {
      document.getElementById('content-container').scrollTo(0, 0);
    },
  },
};
</script>

<style>
.idx-table.b-table.is-loading:after {
  top: 0;
}
</style>
