const jobStatusMapping = Object.freeze({
  0: {
    label: 'not-launch',
    statLabel: 'numberOfNotLaunch',
    color: '#F5F5F5',
  },
  1: {
    label: 'in-queue',
    statLabel: 'numberOfInQueue',
    color: '#F5F5F5',
  },
  2: {
    label: 'running',
    statLabel: 'numberOfRunning',
    color: '#209CEE',
  },
  3: {
    label: 'success',
    statLabel: 'numberOfSuccess',
    color: '#23D160',
  },
  4: {
    label: 'failed',
    statLabel: 'numberOfFailed',
    color: '#FF3860',
  },
  5: {
    label: 'indeterminate',
    statLabel: 'numberOfIndeterminate',
    color: '#F5F5F5',
  },
  6: {
    label: 'wait',
    statLabel: 'numberOfWait',
    color: '#F5F5F5',
  },
  8: {
    label: 'killed',
    statLabel: 'numberOfKilled',
    color: '#363636',
  },
  10: {
    label: 'canceled',
    statLabel: 'numberOfCanceled',
    color: '#F5F5F5',
  },
});

export default jobStatusMapping;
