<template>
  <div class="job-details-wrapper relative">
    <BLoading :is-full-page="false" :active.sync="loading" class="small" />
    <template v-if="!loading">
      <table class="table relative">
        <tbody>
          <tr v-if="isRunning">
            <td>{{ $t('progress') }}</td>
            <td>
              <progress
                :value="job.progress"
                class="progress is-info"
                max="100"
              >
                {{ job.progress }}%
              </progress>
            </td>
          </tr>
          <tr>
            <td>{{ $t('status-comment') }}</td>
            <td>{{ $t(labels[job.status].label) || '-' }}</td>
          </tr>
          <tr v-if="currentUser.adminByNow">
            <td>{{ $t('error-log') }}</td>
            <td>
              <IdxTable
                :await="request"
                :data="jobDataStatuses"
                :current-page.sync="currentPage"
                :per-page.sync="perPage"
                :sort-by.sync="sortBy"
                :sort-direction.sync="sortDirection"
                :total="total"
                :checkable="false"
                class="table is-narrow inline-table is-fullwidth"
              >
                <template #default>
                  <BTableColumn
                    v-slot="props"
                    :label="'id'"
                    field="id"
                    sortable
                    width="100"
                  >
                    {{ props.row.id }}
                  </BTableColumn>

                  <BTableColumn
                    v-slot="props"
                    :label="$t('job-instance-name')"
                    field="name"
                    sortable
                    width="400"
                  >
                    {{ props.row.instanceFilename }}
                  </BTableColumn>

                  <BTableColumn
                    v-slot="props"
                    :label="$t('error-text')"
                    field="errorText"
                    sortable
                    width="1000"
                  >
                    <div v-if="isNullOrNa(props.row.errorText)">
                      {{ props.row.errorText }}
                    </div>
                    <!-- eslint-disable vuejs-accessibility/aria-props -->
                    <BCollapse
                      v-else
                      :open="false"
                      :aria-id="`toggle-${props.row.errorText}`"
                    >
                      <!-- eslint-enable vuejs-accessibility/aria-props -->
                      <IdxBtn
                        slot="trigger"
                        :aria-controls="`toggle-${props.row.errorText}`"
                      >
                        {{ $t('button-show') }}
                      </IdxBtn>

                      <pre>{{ props.row.errorText }}</pre>
                    </BCollapse>
                  </BTableColumn>
                </template>
              </IdxTable>
            </td>
          </tr>
          <tr>
            <td>{{ $t('parameters') }}</td>
            <td>
              <table>
                <tbody>
                  <tr v-for="param in assayParams" :key="param">
                    <td style="font-size:0.9em;">
                      {{ param.name }}
                    </td>
                    <td>{{ param.value }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { JobStatus } from 'cytomine-client';
import IdxTable, { DEFAULTS } from '../utils/IdxTable.vue';
import jobStatusMapping from '../../utils/job-utils.js';
import noteApi from '@/services/noteApi';

export default {
  name: 'JobDetails',
  components: {
    IdxTable,
  },
  props: {
    job: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      error: false,

      timeoutRefresh: null,

      jobDataStatuses: [],
      request: null,

      sortBy: 'id',
      sortDirection: 'desc',
      perPage: DEFAULTS.perPage,
      currentPage: 1,
      total: 0,

      assayParams: [],
    };
  },
  computed: {
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {boolean} */
    canManageJob() {
      return this.$store.getters['currentProject/canManageJob'](this.job);
    },
    /** @returns {boolean} */
    isRunning() {
      return this.job.status === JobStatus.RUNNING;
    },
    /** @returns {boolean} */
    isSuccessful() {
      return this.job.status === JobStatus.SUCCESS;
    },
    /** @returns {boolean} */
    isFinished() {
      return (
        this.isSuccessful ||
        this.job.status === JobStatus.FAILED ||
        this.job.status === JobStatus.KILLED
      );
    },
    /** @returns {Record<number, { label: string, statLabel: string, color: string }>} */
    labels() {
      return jobStatusMapping;
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchJobDataStatus();
      },
    },
  },
  mounted() {
    this.fetchJobDataStatus();
    this.fetchAssayParams();
    this.loading = false;
  },
  beforeDestroy() {
    clearTimeout(this.timeoutRefresh);
  },
  methods: {
    isNullOrNa(errorText) {
      return errorText === null || errorText === 'na';
    },

    async fetchAssayParams() {
      var assayJson = await noteApi.post(`napi/file//download`, {
        json: {
          path: this.job.configFilename,
        },
      });
      this.assayParams = assayJson.user_params;
    },

    fetchJobDataStatus() {
      this.request = async () => {
        try {
          /** @type {{results: Array, total: number}} */
          // @ts-ignore
          const data = await noteApi.get(`napi/job/${this.job.id}`, {
            query: {
              page: this.currentPage - 1,
              perPage: this.perPage,
              sortBy: this.sortBy,
              sortDirection: this.sortDirection,
            },
          });
          this.jobDataStatuses = data.results;
          this.total = data.total;

          if ((this.currentPage - 1) * this.perPage >= this.total) {
            this.currentPage = 1;
          }
        } catch (error) {
          console.log(error);
          this.error = error;
        }
      };
      // Need to initialize the request first
      // so that when the table is rendered, the request can be called
      // reasons unknown
      this.request();
    },
  },
};
</script>

<style scoped>
.job-details-wrapper {
  min-height: 50px;
}

.table {
  background: none;
  margin-bottom: 0 !important;
}

td:first-child {
  white-space: nowrap;
  font-weight: 600;
}

td:not(:first-child) {
  width: 100%;
}

.inline-table td:first-child {
  font-weight: normal;
}

.inline-table td:not(:first-child) {
  width: auto;
}

ul {
  margin-left: 2em;
  list-style-type: disc;
}

.has-margin-top {
  margin-top: 1.5em;
}

.loading {
  display: block;
}

pre {
  white-space: pre-wrap;
  word-break: break-all;
}
</style>
