<template>
  <div>
    <CytomineModal
      :active="active"
      :title="$t('channels-rename')"
      @close="$emit('update:active', false)"
    >
      <BLoading :is-full-page="false" :active="loading" class="small" />
      <template v-if="!loading">
        <IdxTable
          :await="fetchChannels"
          :data="colorMaps"
          :current-page.sync="currentPage"
          :per-page.sync="perPage"
          :sort-by.sync="sortBy"
          :sort-direction.sync="sortDirection"
          :detailed="false"
        >
          <template #default>
            <BTableColumn
              v-slot="props"
              :label="$t('channel')"
              field="columnName"
              class="word-break-all"
              width="15rem"
              searchable
              :debounce-search="500"
            >
              {{ props.row.columnName }}
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('group')"
              class="word-break-all"
              width="15rem"
              :debounce-search="500"
            >
              {{ findGroupName(props.row) }}
            </BTableColumn>

            <BTableColumn v-slot="props" label=" " centered>
              <IdxBtn small class="mr-2" @click="renameChannel(props.row)">
                <span class="icon is-small">
                  <i class="fas fa-edit" />
                </span>
              </IdxBtn>
              <IdxBtn small class="mr-2" @click="deleteChannelPopup(props.row)">
                <span class="icon is-small">
                  <i class="far fa-trash-alt" />
                </span>
              </IdxBtn>
            </BTableColumn>
          </template>

          <template #empty>
            <div class="content has-text-grey has-text-centered">
              <p>{{ $t('no-channels') }}</p>
            </div>
          </template>
        </IdxTable>
      </template>
      <RenameModal
        :active.sync="showNamingModal"
        :title="$t('rename')"
        :current-name="channel.columnName"
        @rename="saveChannel"
      />
    </CytomineModal>
  </div>
</template>

<script>
import noteApi from '../../services/noteApi.js';

import IdxTable, { DEFAULTS } from '../utils/IdxTable.vue';
import CytomineModal from '@/components/utils/CytomineModal.vue';
import RenameModal from '@/components/utils/RenameModal.vue';

export default {
  name: 'ChannelRenameModal',
  components: {
    CytomineModal,
    RenameModal,
    IdxTable,
  },
  props: {
    active: Boolean,
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      fetchChannels: null,
      loading: true,
      perPage: DEFAULTS.perPage,
      sortBy: 'channelName',
      sortDirection: 'desc',
      currentPage: 1,
      colorGroups: [],
      colorGroupNames: new Map(),
      colorMaps: [],
      showNamingModal: false,
      channel: '',
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
  },
  watch: {
    // Reset list after close modal
    active(val) {
      if (!val) {
        this.colorGroups = [];
        this.colorGroupNames.clear();
        this.colorMaps = [];
      }
    },
  },
  async created() {
    this.loading = false;
    this.fetchColorGroups();
  },
  methods: {
    fetchColorGroups() {
      this.fetchChannels = async () => {
        try {
          this.colorGroups = await noteApi.get(
            `/napi/project/${this.project.id}/colormaps`
          );
          this.sortColorGroups(this.colorGroups);
        } catch (error) {
          console.log(error);
        }
      };
    },
    sortColorGroups(groups) {
      const map = [];
      for (const group of groups) {
        map.push(group.colorMaps);
        const id = group.id;
        this.colorGroupNames.set(id, group.groupName);
      }
      // Store groupID and matching groupName
      for (const color of map) {
        this.colorMaps = this.colorMaps.concat(color);
      }
    },
    findGroupName(color) {
      if (this.colorGroupNames.get(color.colorMapGroupId).length != 0) {
        return this.colorGroupNames.get(color.colorMapGroupId);
      } else {
        return 'Not Available';
      }
    },
    renameChannel(prop) {
      this.channel = prop;
      this.showNamingModal = true;
    },
    async saveChannel(newName) {
      const oldName = this.channel.columnName;

      try {
        const response = await noteApi.patch(
          `napi/project/${this.project.id}/colormap/${this.channel.colorMapGroupId}/color/${this.channel.id}`,
          {
            json: {
              colorName: newName,
            },
          }
        );
        if (response) {
          this.channel.columnName = newName;

          this.channel.name = newName;
          this.colorMaps[
            this.colorMaps.indexOf(this.channel)
          ].columnName = newName;
          this.colorMaps[this.colorMaps.indexOf(this.channel)].name = newName;
          this.$emit('updateOldColorMaps', this.colorMaps);

          const oldChannel = { ...this.channel };
          oldChannel.columnName = oldName;
          oldChannel.name = oldName;
          const channelArray = [{ ...oldChannel }, { ...this.channel }];
          this.$emit('updateOldChannels', channelArray);

          this.$notify({
            type: 'success',
            text: this.$t('channel-rename-success', {
              oldName: oldName,
              newName: newName,
            }),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('channel-rename-error', {
              channelName: oldName,
            }),
          });
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('channel-rename-error', {
            channelName: oldName,
          }),
        });
      }
    },
    deleteChannelPopup(prop) {
      this.channel = prop;
      this.$buefy.dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('channel-delete-confirmation-message', {
          channelName: this.channel.columnName,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteChannel(),
      });
    },
    async deleteChannel() {
      try {
        await noteApi.delete(
          `napi/project/${this.project.id}/colormap/${this.channel.colorMapGroupId}/color/${this.channel.id}`
        );
        this.colorMaps.splice(this.colorMaps.indexOf(this.channel), 1);
        this.$notify({
          type: 'success',
          text: this.$t('channel-delete-success', {
            channelName: this.channel.columnName,
          }),
        });
        this.$emit('deletedChannel', this.channel);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('channel-delete-error', {
            channelName: this.channel.columnName,
          }),
        });
      }
    },
  },
};
</script>

<style scoped>
>>> .animation-content {
  max-width: 60% !important;
  width: 60%;
}
</style>
