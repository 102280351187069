<template>
  <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
    <slot name="error">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </slot>
  </BMessage>
  <div v-else class="cytomine-table-wrapper">
    <BLoading :is-full-page="false" :active="!data" />
    <BTable
      v-if="data"
      :data="data"
      :loading="loading"
      :total="total"
      :per-page="internalPerPage"
      :current-page.sync="internalCurrentPage"
      :detailed="detailed"
      :detail-key="detailKey"
      :opened-detailed="openedDetailed.slice()"
      :default-sort="sort"
      :default-sort-direction="order"
      :checkable="checkable"
      :checked-rows.sync="internalCheckedRows"
      :custom-is-checked="customIsCheckedFn"
      :is-row-checkable="isRowCheckable"
      paginated
      backend-pagination
      pagination-size="is-small"
      backend-sorting
      @update:openedDetailed="$emit('update:openedDetailed', $event)"
      @sort="updateSort"
    >
      <template #header="{ column }">
        <slot :column="column" name="header" :index="index">
          {{ column.label }}
        </slot>
      </template>

      <slot v-bind="props" name="default" />

      <template #detail="{ row, index }">
        <slot :row="row" name="detail" :index="index" />
      </template>

      <template #empty>
        <div class="content has-text-grey has-text-centered">
          <slot :row="row" name="empty" :index="index">
            {{ $t('no-result') }}
          </slot>
        </div>
      </template>

      <template #footer>
        <slot name="footer" />
      </template>

      <template #bottom-left>
        <BSelect v-model="internalPerPage" size="is-small">
          <option
            v-for="option in perPageOptions"
            :key="option"
            :value="option"
          >
            {{ $t('count-per-page', { count: option }) }}
          </option>
        </BSelect>
      </template>
    </BTable>
  </div>
</template>

<script>
const internalCheckedMixin = {
  props: {
    checkedRows: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    checkedValues: [],
    internalCheckedRows: [],
  }),
  watch: {
    internalCheckedRows(checkedRows) {
      this.$emit('update:checkedRows', checkedRows);
    },
  },
  created() {
    this.internalCheckedRows = this.checkedRows;
  },
  methods: {
    customIsCheckedFn(checkedRow, currentRow) {
      return checkedRow.id === currentRow.id;
    },
  },
};

export default {
  name: 'CytomineTable',
  mixins: [internalCheckedMixin],
  props: {
    collection: {
      type: Object,
      required: true,
    },
    perPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    perPage: {
      type: Number,
      default: 25,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    detailed: {
      type: Boolean,
      default: true,
    },
    detailKey: {
      type: String,
      default: 'id',
    },
    openedDetailed: {
      type: Array,
      default: () => [],
    },
    sort: { type: String },
    order: {
      type: String,
      default: 'asc',
    },
    revision: {
      type: Number, // updating this value will result in update of table data
      default: 0,
    },
    loadRevisions: {
      type: Boolean,
      default: false,
    },
    refreshInterval: {
      type: Number,
      default: 0,
    }, // if > 0, table data will be refreshed according to refreshInterval (expressed in ms)
    checkable: {
      type: Boolean,
      default: false,
    },
    isRowCheckable: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      index: 0,
      row: null,
      data: null,
      error: false,
      loading: true,
      total: 0,
      internalCurrentPage: null,
      internalPerPage: null,
      internalSort: null,
      internalOrder: null,
      timeout: null,
    };
  },
  computed: {
    /** @returns {object} */
    internalCollection() {
      const collection = this.collection.clone();
      collection.max = this.internalPerPage;
      collection.sort = this.internalSort;
      collection.order = this.internalOrder;
      return collection;
    },
  },
  watch: {
    internalCollection() {
      this.fetchPage(true);
    },
    internalCurrentPage(page, previousValue) {
      if (previousValue) {
        // do not trigger change for value initialization
        this.$emit('update:currentPage', page);
        this.fetchPage(true);
      }
    },
    internalPerPage(perPage) {
      this.$emit('update:perPage', perPage);
    },
    revision() {
      this.fetchPage(this.loadRevisions);
    },
    refreshInterval(value) {
      if (value === 0) {
        clearTimeout(this.timeout);
        this.timeout = null;
      } else if (!this.timeout) {
        // user provided a valid refresh interval, and no fetch page planned => relaunch fetch page
        this.fetchPage();
      }
    },
  },
  created() {
    this.internalCurrentPage = this.currentPage;
    this.internalPerPage = this.perPage;
    this.internalSort = this.sort;
    this.internalOrder = this.order;
  },
  methods: {
    updateSort(sort, order) {
      this.internalSort = sort;
      this.internalOrder = order;
      this.$emit('update:sort', sort);
      this.$emit('update:order', order);
    },
    async fetchPage(showLoading = false) {
      this.error = false;
      if (showLoading) {
        this.loading = true;
      }

      try {
        const data = await this.internalCollection.fetchPage(
          this.internalCurrentPage - 1
        );
        this.data = data.array;
        this.total = data.totalNbItems;

        this.$emit('update:data', data);
      } catch (error) {
        if (this.internalCurrentPage > 1) {
          // error may be due to the page number (not enough elements) => retry on first page
          this.internalCurrentPage = 1;
          return;
        }

        console.log(error);
        this.error = true;
        return;
      }

      this.loading = false;

      clearTimeout(this.timeout);
      if (this.refreshInterval) {
        this.timeout = setTimeout(this.fetchPage, this.refreshInterval);
      }
    },
  },
};
</script>

<style scoped>
.cytomine-table-wrapper {
  min-height: 10em;
  position: relative;
}
</style>
