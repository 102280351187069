<template>
  <BProgress
    class="data-bar"
    type="is-info"
    :value="value"
    size="is-medium"
    show-value
    :max="Number(max)"
  >
    {{ value }} / {{ max }}
  </BProgress>
</template>

<script>
export default {
  name: 'DataField',
  props: {
    /** @type {import('vue').PropOptions<CytoImageInstance & { properties: Array<{ key: string, value: any}>}>} */
    image: { type: Object, required: true },
    max: { type: [Number, String], default: 100 },
  },
  computed: {
    /** @returns {number} */
    value() {
      const resultProperty = this.image.properties.find(
        (prop) => prop.key.toLowerCase() === '@result'
      );

      if (!resultProperty) return 0;
      return Number(Number(resultProperty.value).toFixed(2));
    },
  },
};
</script>

<style scoped>
.data-bar {
  min-width: 6rem;
}
</style>
