import { render, staticRenderFns } from "./IdxBtn.vue?vue&type=template&id=5f882861&scoped=true&"
import script from "./IdxBtn.vue?vue&type=script&lang=js&"
export * from "./IdxBtn.vue?vue&type=script&lang=js&"
import style0 from "./IdxBtn.vue?vue&type=style&index=0&id=5f882861&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f882861",
  null
  
)

export default component.exports