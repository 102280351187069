<template>
  <VBtn
    :class="[
      typeClass,
      colorClass,
      {
        'is-small': small,
        'is-fullwidth': fullWidth,
        'color-blue': link && !color,
      },
    ]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <BIcon
      v-if="loading"
      pack="fas"
      icon="sync-alt"
      class="fa-spin"
      :aria-label="$t('loading')"
    />

    <slot v-else />
  </VBtn>
</template>

<script>
import VBtn from 'vuetensils/src/components/VBtn/VBtn.vue';

export default {
  name: 'IdxBtn',
  components: {
    VBtn,
  },
  props: {
    color: {
      type: String,
      default: '',
    },
    plain: Boolean,
    link: Boolean,
    small: Boolean,
    fullWidth: Boolean,
    loading: Boolean,
  },
  computed: {
    /** @returns {'btn-plain' | 'btn-link' | 'button'} */
    typeClass() {
      return this.plain ? 'btn-plain' : this.link ? 'btn-link' : 'button';
    },
    colorClass() {
      switch (this.color) {
        case 'blue':
        case 'info':
        case 'primary':
          return 'is-link';

        case 'red':
        case 'danger':
        case 'error':
          return 'is-danger';

        case 'yellow':
        case 'warning':
          return 'is-warning';

        case 'green':
        case 'success':
          return 'is-success';

        default:
          return '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-link {
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer !important;
  padding: 0;
}
.btn-link.is-danger {
  color: $red;
}
.btn-link.is-small {
  font-size: 1em;
}
.btn-link:hover {
  text-decoration: underline !important;
}
</style>
