<template>
  <BModal :active="active" has-modal-card @close="close">
    <CytomineModalCard :title="title" @close="close">
      <slot />
      <template #footer>
        <slot name="footer" />
      </template>
    </CytomineModalCard>
  </BModal>
</template>

<script>
import CytomineModalCard from './CytomineModalCard.vue';

export default {
  name: 'CytomineModal',
  components: { CytomineModalCard },
  props: {
    active: Boolean,
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.$emit('update:active', false);
    },
  },
};
</script>
