<template>
  <VMultiselect
    :value="value"
    :label="label"
    :track-by="trackBy"
    :group-label="groupLabel"
    :group-values="groupValues"
    group-select
    :options="options"
    :option-height="30"
    :show-labels="false"
    :multiple="multiple"
    :close-on-select="!multiple"
    :searchable="searchable"
    :clear-on-select="!multiple && clearOnSelect"
    :show-pointer="false"
    :placeholder="$t('select-options')"
    :allow-empty="allowEmpty"
    :disabled="disabled"
    :preserve-search="preserveSearch"
    @input="$emit('input', $event)"
    @search-change="fetchOptions"
  >
    <template
      v-if="multiple && options.length > 0 && selectAllAvailable"
      #beforeList
    >
      <li
        class="multiselect__element border-b border-gray-1"
        @click="selectAll"
      >
        <span
          :class="[
            'multiselect__option',
            allSelected ? 'multiselect__option--selected' : '',
          ]"
        >
          {{ $t('select-all') }}
        </span>
      </li>
    </template>

    <template v-if="multiple && options.length > 0" #selection="{ isOpen }">
      <div v-if="!isOpen" class="multiselect__tags-wrap">
        <strong v-if="allSelected"> {{ allPlaceholder || $t('all') }} </strong>
        <template v-else>
          <span
            v-for="(option, index) in displayedOptions"
            :key="option[trackBy]"
          >
            {{ label ? option[label] : option
            }}<template v-if="index < displayedOptions.length - 1">,</template>
          </span>
          <strong v-if="countNotDisplayed > 0">
            {{
              $tc('and-count-others', countNotDisplayed, {
                count: countNotDisplayed,
              })
            }}
          </strong>
        </template>
      </div>
      <div v-else />
    </template>

    <template #option="{ option }">
      <slot :option="option" name="option" />
    </template>
  </VMultiselect>
</template>

<script>
import VMultiselect from 'vue-multiselect';

export default {
  name: 'CytomineMultiselect',
  components: { VMultiselect },
  props: {
    value: { type: undefined, default: undefined },
    options: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    trackBy: { type: String, default: '' },
    groupLabel: { type: String, default: '' },
    groupValues: { type: String, default: '' },
    multiple: { type: Boolean, default: false },
    selectAllAvailable: { type: Boolean, default: true },
    searchable: { type: Boolean, default: true },
    allowEmpty: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    allPlaceholder: { type: String, default: '' },
    preserveSearch: { type: Boolean, default: false },
    clearOnSelect: { type: Boolean, default: true },
    fetchOptions: { type: Function, default: () => [] },
  },
  data() {
    return {
      maxNbDisplayed: 3,
    };
  },
  computed: {
    /** @returns {boolean} */
    allSelected() {
      if (!this.multiple) {
        return false;
      }
      // @ts-ignore
      return this.options.every((opt) => this.value.includes(opt));
    },
    /** @returns {Array} */
    displayedOptions() {
      // @ts-ignore
      return this.value.slice(0, this.maxNbDisplayed);
    },
    /** @returns {number} */
    countNotDisplayed() {
      // @ts-ignore
      return this.value.length - this.maxNbDisplayed;
    },
  },
  methods: {
    selectAll() {
      const newValue = this.allSelected ? [] : this.options;
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.multiselect--active,
.multiselect__content-wrapper {
  z-index: 50 !important;
}

.multiselect__option--selected::before {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  position: absolute;
  left: 10px;
}

.multiselect__input {
  color: #35495e;
}

.multiselect__option {
  padding: 6px 5px 5px 30px !important;
  font-size: 1rem !important;
  min-height: 30px !important;
}

.multiselect__option:hover {
  background: #61b2e8 !important;
  color: white;
}

.is-danger .multiselect__tags {
  border-color: $red;
}

.is-danger .multiselect__select::before {
  border-color: $red transparent transparent;
}
</style>
