import Vue from 'vue';
import Chart from 'chart.js';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import updateLocale from 'dayjs/plugin/updateLocale.js';
import localeData from 'dayjs/plugin/localeData.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'bedrocss';
import './plugins/index.js';
import router from './router.js';
import i18n from './lang.js';
import store from './store/store.js';
import App from './App.vue';

Chart.plugins.unregister(ChartDataLabels);
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  anchor: 'end',
  align: 'end',
  offset: 5,
  clamp: true,
});

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  Vue.prototype.$log = console.log;
}

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

const globals = require.context('@/components/global', true, /\.vue$/);
globals.keys().forEach((path) => {
  const config = globals(path);
  const fileName = path.slice(path.lastIndexOf('/'));
  const name = config.default.name || fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
  Vue.component(name, config.default || config);
});

dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(localeData);
Vue.filter('date', (value, options) => dayjs(value).format(options));

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount(`#app`);
