<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <span
      :class="{ 'is-active': isActive, ...linkClasses }"
      class="navbar-link"
      tabindex="0"
    >
      <i v-if="icon" :class="['pr-2', iconPack, icon]" />
      {{ title }}
      <BTag v-if="tag" :type="tag.type" class="ml-2">{{ tag.text }}</BTag>
    </span>
    <div :class="classes" class="navbar-dropdown">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarDropdown',
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconPack: {
      type: String,
      default: 'fas',
    },
    title: {
      type: String,
      required: true,
    },
    tag: {
      type: Object,
      default: null,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    linkClasses: {
      type: Object,
      default: () => ({}),
    },
    listPathes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    '$route.path': {
      handler(newPath) {
        if (this.listPathes) {
          this.isActive = this.listPathes.includes(newPath);
        }
        document.activeElement.blur();
      },
      immediate: true,
    },
  },
};
</script>
