<template>
  <div class="cytomine-quill-editor">
    <div id="tooltip-container" />
    <!-- invisible div defining the allowed positions for ql tooltip -->

    <quill-editor
      ref="quillEditor"
      :value="value"
      :options="editorOptions"
      @input="$emit('input', $event)"
    >
      <template #toolbar>
        <div id="toolbar">
          <span class="ql-formats">
            <select class="ql-header">
              <option selected>{{ $t('ql-paragraph') }}</option>
              <option value="1">{{ $t('ql-header-1') }}</option>
              <option value="2">{{ $t('ql-header-2') }}</option>
              <option value="3">{{ $t('ql-header-3') }}</option>
              <option value="4">{{ $t('ql-header-4') }}</option>
            </select>
          </span>

          <span :title="$t('ql-font')" class="ql-formats">
            <select class="ql-font">
              <option selected />
              <option value="serif" />
              <option value="monospace" />
            </select>
          </span>

          <span class="ql-formats">
            <button :title="$t('ql-bold')" class="ql-bold" />
            <button :title="$t('ql-italic')" class="ql-italic" />
            <button :title="$t('ql-underline')" class="ql-underline" />
          </span>

          <!-- Add subscript and superscript buttons -->
          <span class="ql-formats">
            <button class="ql-script" value="sub" :title="$t('ql-subscript')" />
            <button
              :title="$t('ql-superscript')"
              class="ql-script"
              value="super"
            />
          </span>

          <span class="ql-formats">
            <span :title="$t('ql-font-color')">
              <select class="ql-color" />
            </span>
            <span :title="$t('ql-background-color')">
              <select class="ql-background" />
            </span>
          </span>

          <span :title="$t('ql-align')" class="ql-formats">
            <select class="ql-align">
              <option selected />
              <option value="center" />
              <option value="right" />
            </select>
          </span>

          <span class="ql-formats">
            <button
              :title="$t('ql-indent-left')"
              class="ql-indent"
              value="-1"
            />
            <button
              :title="$t('ql-indent-right')"
              class="ql-indent"
              value="+1"
            />
          </span>

          <span class="ql-formats">
            <button
              :title="$t('ql-ordered-list')"
              class="ql-list"
              value="ordered"
            />
            <button
              :title="$t('ql-bullet-list')"
              class="ql-list"
              value="bullet"
            />
          </span>

          <span class="ql-formats">
            <button :title="$t('ql-blockquote')" class="ql-blockquote" />
            <button :title="$t('ql-code-block')" class="ql-code-block" />
          </span>

          <span class="ql-formats">
            <button :title="$t('ql-clean-format')" class="ql-clean" />
          </span>

          <span class="ql-formats">
            <button :title="$t('ql-link')" class="ql-link" />
            <button :title="$t('ql-image')" class="ql-image" />
            <button :title="$t('ql-video')" class="ql-video" />
          </span>

          <span class="ql-formats">
            <span
              v-click-outside="() => (expandedSpecialChars = false)"
              :class="[
                'ql-picker',
                expandedSpecialChars ? 'ql-expanded' : '',
                'ql-special-characters',
              ]"
              :title="$t('ql-special-character')"
              @click="expandedSpecialChars = !expandedSpecialChars"
            >
              <span class="ql-picker-label" tabindex="0" role="button">
                <svg viewBox="0 0 18 18">
                  <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11" />
                  <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7" />
                </svg>
              </span>

              <span class="ql-picker-options">
                <button
                  v-for="char in specialCharacters"
                  :key="char"
                  class="special-char"
                  @click="insertSpecialCharacter(char)"
                >
                  {{ char }}
                </button>
              </span>
            </span>
          </span>
        </div>
      </template>
    </quill-editor>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'CytomineQuillEditor',
  components: { quillEditor },
  props: {
    value: String,
    placeholder: String,
  },
  data() {
    return {
      editorOptions: {
        bounds: '#tooltip-container',
        placeholder: this.placeholder || this.$t('enter-text'),
        modules: {
          toolbar: '#toolbar',
        },
      },
      expandedSpecialChars: false,
      specialCharacters: [
        '$',
        '€',
        '£',
        '¢',
        '¥',
        '¤',
        '‰',
        '©',
        '®',
        '™',
        '§',
        '¶',
        'Æ',
        'æ',
        'Œ',
        'œ',
        '±',
        '×',
        '÷',
        '⇒',
        '⇔',
        '∏',
        '∑',
        '≃',
        '≤',
        '≥',
      ],
    };
  },
  computed: {
    quill() {
      return this.$refs.quillEditor.quill;
    },
  },
  methods: {
    insertSpecialCharacter(char) {
      const range = this.quill.getSelection(true);
      if (range.length > 0) {
        this.quill.deleteText(range.index, range.length, 'user');
      }
      this.quill.insertText(range.index, char, 'user');
      this.quill.setSelection(range.index + 1);
    },
  },
};
</script>

<style lang="scss">
.cytomine-quill-editor {
  position: relative;
  min-height: 30vh;
  display: flex;
  flex-direction: column;

  .ql-toolbar {
    flex-shrink: 0;
  }

  .ql-bold svg,
  .ql-italic svg,
  .ql-underline svg {
    display: none;
  }

  .ql-bold::before {
    content: 'A';
    font-weight: 600;
  }

  .ql-italic::before {
    content: 'A';
    font-style: italic;
  }

  .ql-underline::before {
    content: 'A';
    text-decoration: underline;
  }

  .ql-picker.ql-special-characters .ql-picker-label::before {
    content: '\03A9'; /* Omega */
    font-weight: 600;
    margin-right: 1.5em;
  }

  .ql-picker.ql-special-characters .ql-picker-options {
    white-space: normal;
    width: 13.3em;
  }

  .special-char {
    display: inline-block !important;
  }

  .special-char:hover {
    background: #f5f5f5 !important;
  }

  #tooltip-container {
    position: absolute;
    left: 1em;
    right: 1em;
    top: 0;
    bottom: 0;
    pointer-events: none; /* to allow selection of elements below it */
  }

  .quill-editor {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .ql-container {
    overflow: auto;
    flex-grow: 1;
  }
}
</style>
