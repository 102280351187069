<template>
  <CytomineModal
    :active="active"
    :title="$t('add-images')"
    @close="$emit('update:active', false)"
  >
    <BLoading :is-full-page="false" :active="loading" class="small" />
    <template v-if="!loading">
      <BInput
        v-model="searchString"
        :placeholder="$t('search')"
        class="search-images"
        type="search"
        icon="search"
      />

      <CytomineTable
        :collection="imageCollection"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :sort.sync="sortField"
        :order.sync="sortOrder"
        :detailed="false"
      >
        <template #default>
          <BTableColumn v-slot="props" :label="$t('overview')">
            <img
              :src="props.row.previewURL(256)"
              class="image-overview"
              alt=""
            />
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('name')"
            field="originalFilename"
            class="word-break-all"
            width="15rem"
            sortable
          >
            {{ props.row.originalFilename }}
          </BTableColumn>

          <BTableColumn
            v-slot="props"
            :label="$t('created-on')"
            field="created"
            sortable
          >
            {{ Number(props.row.created) | date('ll LT') }}
          </BTableColumn>

          <BTableColumn v-slot="props" label=" " centered>
            <button
              v-if="wasAdded(props.row)"
              class="button is-small is-link"
              disabled
            >
              {{ $t('button-added') }}
            </button>
            <span v-else-if="isInProject(props.row)">
              {{ $t('already-in-project') }}
            </span>
            <button
              v-else
              class="button is-small is-link"
              @click="addImage(props.row)"
            >
              {{ $t('add') }}
            </button>
          </BTableColumn>
        </template>

        <template #empty>
          <div class="content has-text-grey has-text-centered">
            <p>{{ $t('no-image') }}</p>
          </div>
        </template>
      </CytomineTable>
    </template>
  </CytomineModal>
</template>

<script>
import { AbstractImageCollection, ImageInstance } from 'cytomine-client';
import CytomineModal from '@/components/utils/CytomineModal.vue';
import CytomineTable from '@/components/utils/CytomineTable.vue';

export default {
  name: 'AddImageModal',
  components: {
    CytomineTable,
    CytomineModal,
  },
  props: {
    active: Boolean,
  },
  data() {
    return {
      loading: true,
      perPage: 10,
      searchString: '',
      idsAddedImages: [],
      currentPage: 1,
      sortField: 'created',
      sortOrder: 'desc',
    };
  },
  computed: {
    project() {
      return this.$store.state.currentProject.project;
    },
    imageCollection() {
      const collection = new AbstractImageCollection({
        project: this.project.id,
      });
      if (this.searchString) {
        collection['originalFilename'] = {
          ilike: encodeURIComponent(this.searchString),
        };
      }

      return collection;
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.idsAddedImages = [];
      }
    },
  },
  async created() {
    this.loading = false;
  },
  methods: {
    async addImage(abstractImage) {
      const propsTranslation = {
        imageName: abstractImage.originalFilename,
        projectName: this.project.name,
      };
      try {
        const image = await new ImageInstance({
          baseImage: abstractImage.id,
          project: this.project.id,
        }).save();
        this.idsAddedImages.push(abstractImage.id);
        this.$emit('addImage', image);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-add-image', propsTranslation),
        });

        const updatedProject = this.project.clone();
        updatedProject.numberOfImages++;
        this.$store.dispatch('currentProject/updateProject', updatedProject);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-add-image', propsTranslation),
        });
      }
    },
    isInProject(image) {
      return image.inProject;
    },
    wasAdded(image) {
      return this.idsAddedImages.includes(image.id);
    },
  },
};
</script>

<style scoped>
>>> .animation-content {
  max-width: 60% !important;
  width: 60%;
}

>>> .modal-card {
  width: 100%;
  height: 80vh;
}

.image-overview {
  max-height: 4rem;
  max-width: 10rem;
}
</style>
