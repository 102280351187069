<template>
  <div class="navigation-tree-wrapper">
    <div v-for="(project, _, index) in projects" :key="project.id">
      <router-link
        :to="`/project/${project.id}`"
        class="navbar-item project-item"
      >
        {{ project.name }}
        <a
          class="delete is-small"
          @click.stop.prevent="closeProject(project)"
        />
      </router-link>

      <template v-for="(viewer, idViewer) in project.viewers">
        <router-link
          v-if="nbImages(viewer)"
          :key="idViewer"
          :to="viewerPath(project.id, idViewer)"
          class="navbar-item viewer-item"
          exact
        >
          <div class="viewer-name">
            <span>
              <i class="fas fa-caret-right" />
              <template v-if="nbImages(viewer) === 1">
                <ImageName
                  :image="Object.values(viewer.images)[0].imageInstance"
                />
              </template>
              <template v-else>
                {{ $t('viewer-group', { nbImages: nbImages(viewer) }) }}
              </template>
              {{ viewer.name }}
            </span>
            <ul v-if="nbImages(viewer) > 1" class="viewer-details">
              <li v-for="(image, idx) in viewer.images" :key="idx">
                <ImageName :image="image.imageInstance" />
              </li>
            </ul>
          </div>
          <a
            class="delete is-small"
            @click.stop.prevent="closeViewer(project.id, idViewer)"
          />
        </router-link>
      </template>
      <hr v-if="index < projects.length - 1" class="navbar-divider" />
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/store-helpers';
import ImageName from '@/components/image/ImageName';

export default {
  name: 'NavigationTree',
  components: { ImageName },
  computed: {
    projects: get('projects'),
  },
  methods: {
    nbImages(viewer) {
      return Object.keys(viewer.images).length;
    },
    viewerPath(idProject, idViewer) {
      return this.$store.getters[
        `projects/${idProject}/viewers/${idViewer}/pathViewer`
      ]();
    },
    closeProject(project) {
      const nbViewers = Object.keys(project.viewers).length;
      if (nbViewers) {
        this.$buefy.dialog.confirm({
          title: this.$t('project-confirm-close'),
          message: this.$t('project-message-confirm-close', {
            viewers: this.$tc('count-viewers', nbViewers, { count: nbViewers }),
          }),
          type: 'is-danger',
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
          onConfirm: () => this.doCloseProject(project),
        });
        return;
      }
      this.doCloseProject(project);
    },
    doCloseProject(project) {
      this.$store.unregisterModule(['projects', project.id]);
    },
    closeViewer(idProject, idViewer) {
      this.$store.unregisterModule([
        'projects',
        idProject,
        'viewers',
        idViewer,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.project-item {
  color: #333;
}

.viewer-item {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  position: relative;
}

.viewer-item ul {
  color: #888;
  font-weight: normal;
  margin-left: 1rem;
  font-size: 0.85em;
}

.viewer-name {
  padding-right: 2rem;
}

.delete {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.navigation-tree-wrapper {
  @include desktop {
    max-height: 70vh;
    overflow: auto;
  }
}
</style>
