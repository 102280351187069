<template>
  <div class="project-actions-wrapper">
    <RenameModal
      :title="$t('project-rename')"
      :current-name="project.name"
      :active.sync="isRenameModalActive"
      @rename="rename"
    />

    <form @submit.prevent="saveOntology()">
      <CytomineModal
        :active="isOntologyModalActive"
        :title="$t('change-ontology')"
        @close="isOntologyModalActive = false"
      >
        <b-message
          v-if="errorOntologies"
          type="is-danger"
          has-icon
          icon-size="is-small"
        >
          <h2 class="mb-3">
            {{ $t('error') }}
          </h2>
          <p>{{ $t('unexpected-error-info-message') }}</p>
        </b-message>
        <template v-else>
          <b-message
            v-if="project.ontology"
            type="is-warning"
            has-icon
            icon-size="is-small"
          >
            {{ $t('change-ontology-warning-message') }}
          </b-message>
          <b-field :label="$t('ontology')">
            <b-select
              v-model="selectedOntology"
              :placeholder="$t('no-ontology')"
              :disabled="loadingOntologies"
              :loading="loadingOntologies"
            >
              <option :value="null">
                {{ $t('no-ontology') }}
              </option>
              <option
                v-for="ontology in ontologies"
                :key="ontology.id"
                :value="ontology.id"
              >
                {{ ontology.name }}
              </option>
            </b-select>
          </b-field>
        </template>

        <template #footer>
          <button
            :disabled="savingOntology"
            class="button"
            type="button"
            @click="isOntologyModalActive = false"
          >
            {{ $t('cancel') }}
          </button>
          <button
            v-if="!errorOntologies"
            :class="{ 'is-loading': savingOntology }"
            :disabled="loadingOntologies || savingOntology"
            class="button is-link"
          >
            {{ $t('save') }}
          </button>
        </template>
      </CytomineModal>
    </form>

    <div class="buttons">
      <button :class="size" class="button" @click="isRenameModalActive = true">
        {{ $t('button-rename') }}
      </button>
      <button
        :class="size"
        :disabled="cannotDeleteOntology"
        class="button"
        @click="isOntologyModalActive = true"
      >
        {{ $t('button-change-ontology') }}
      </button>
      <button :class="size" class="button is-danger" @click="deleteProject()">
        {{ $t('delete') }}
      </button>
    </div>
  </div>
</template>

<script>
import { OntologyCollection } from 'cytomine-client';
import CytomineModal from '@/components/utils/CytomineModal';
import RenameModal from '@/components/utils/RenameModal';

export default {
  name: 'ProjectActions',
  components: {
    CytomineModal,
    RenameModal,
  },
  props: {
    project: { type: Object },
    size: {
      type: String,
      default: 'is-small',
    },
  },
  data() {
    return {
      isRenameModalActive: false,
      isOntologyModalActive: false,
      loadingOntologies: true,
      errorOntologies: false,
      ontologies: null,
      selectedOntology: null,
      cannotDeleteOntology: false,
      savingOntology: false,
    };
  },
  watch: {
    async isOntologyModalActive(val) {
      if (val) {
        if (this.loadingOntologies) {
          // first opening of the ontology modal => load ontologies
          try {
            this.ontologies = (
              await OntologyCollection.fetchAll({ light: true })
            ).array;
            this.ontologies.sort((a, b) => a.name.localeCompare(b.name));
            this.loadingOntologies = false;
          } catch (error) {
            console.log(error);
            this.errorOntologies = true;
          }
        }

        // preselect the ontology of the project
        this.selectedOntology = this.project.ontology;
        this.savingOntology = false;
      }
    },
  },
  methods: {
    async rename(newName) {
      const updatedProject = this.project.clone();
      try {
        updatedProject.name = newName;
        await updatedProject.save();
        this.$emit('update', updatedProject);
        this.$notify({
          type: 'success',
          text: this.$t('project-rename-notif-success', {
            projectName: updatedProject.name,
          }),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('project-rename-notif-error', {
            projectName: this.project.name,
          }),
        });
      }
      this.isRenameModalActive = false;
    },

    async saveOntology(forceOntologyUpdate = false) {
      this.savingOntology = true; // possibly long operation => give user visual indication that it is in progress
      const updatedProject = this.project.clone();
      try {
        updatedProject.ontology = this.selectedOntology;
        updatedProject.forceOntologyUpdate = forceOntologyUpdate; // to delete annotationterms if some exist
        await updatedProject.save();
        this.$emit('update', updatedProject);
        this.$notify({
          type: 'success',
          text: this.$t('project-ontology-change-notif-success', {
            projectName: this.project.name,
          }),
        });
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data.errorValues) {
          const counts = error.response.data.errorValues;

          // if the only terms associations are performed by users, ask confirmation and delete them
          if (
            counts.algoAssociatedTermsCount === 0 &&
            counts.reviewedAssociatedTermsCount === 0
          ) {
            this.$buefy.dialog.confirm({
              title: this.$t('confirm-ontology-change'),
              message: this.$t('confirm-ontology-change-delete-user-terms', {
                count: counts.userAssociatedTermsCount,
              }),
              type: 'is-danger',
              confirmText: this.$t('confirm'),
              cancelText: this.$t('cancel'),
              onConfirm: () => this.saveOntology(true),
            });
          } else {
            // otherwise, backend does not allow automatic deletion => notify of error
            this.cannotDeleteOntology = true;
            this.$notify({
              type: 'error',
              text: this.$t(
                `project-ontology-change-${
                  counts.algoAssociatedTermsCount
                    ? 'job-terms'
                    : 'reviewed-terms'
                }-notif-error`
              ),
            });
          }
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('project-ontology-change-notif-error', {
              projectName: this.project.name,
            }),
          });
        }
      }
      this.savingOntology = false;
      this.isOntologyModalActive = false;
    },

    deleteProject() {
      this.$buefy.dialog.confirm({
        title: this.$t('project-delete'),
        message: this.$t('project-delete-confirmation-message', {
          projectName: this.project.name,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.$emit('delete'),
      });
    },
  },
};
</script>
