<template>
  <div>
    <VAsync :await="request">
      <template #default="promise">
        <div>
          <div class="trainings-container p-3">
            <IdxBtn color="white" @click="fetchTrainings">
              <i class="fas fa-sync mr-2" style="font-size:0.7em; " />
              {{ $t('refresh') }}
            </IdxBtn>
            <IdxTable
              :await="request"
              :data="trainings"
              :current-page.sync="currentPage"
              :per-page.sync="perPage"
              :sort-by.sync="sortBy"
              :sort-direction.sync="sortDirection"
              :total="total"
              class="mt-4"
              detailed
              detail-key="pid"
            >
              <template #default>
                <BTableColumn
                  v-slot="props"
                  :label="$t('id')"
                  field="pid"
                  sortable
                  width="200"
                >
                  {{ props.row.pid }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('configuration')"
                  field="name"
                  sortable
                  width="1000"
                >
                  {{ props.row.title }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('launched-by')"
                  field="username"
                  sortable
                  width="1000"
                >
                  {{ props.row.startedByUsername }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('execution-date')"
                  field="created"
                  sortable
                  width="1000"
                >
                  {{ props.row.created }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('duration')"
                  width="500"
                >
                  {{ getDuration(props.row) }}
                </BTableColumn>

                <BTableColumn
                  v-slot="props"
                  :label="$t('status')"
                  field="status"
                  sortable
                  centered
                  width="500"
                >
                  <JobStatusView :status="props.row.status" />
                </BTableColumn>
                <BTableColumn v-slot="props" centered width="50">
                  <IdxBtn
                    color="white"
                    small
                    @click="() => rerunTraining(props.row)"
                  >
                    <i class="fas fa-redo mr-2" style="font-size:0.7em; " />
                    {{ $t('rerun') }}
                  </IdxBtn>
                </BTableColumn>
              </template>

              <template #detail="{row: training}">
                <TrainingDetails :training="training" />
              </template>

              <template #empty>
                <div class="content has-text-grey has-text-centered py-5">
                  <p>{{ $t('no-analysis-run') }}</p>
                </div>
              </template>
            </IdxTable>
          </div>
        </div>
      </template>
    </VAsync>
    <LaunchTrainingModal
      :active.sync="openTrainingModal"
      :selected-training="selectedTraining"
      :selected-training-model="selectedTraining && selectedTraining.model"
      :id-project="project.id"
      :revision="0"
    />
  </div>
</template>

<script>
import { JobStatus } from 'cytomine-client';
import { GetOutputs } from '../../services/trainingFramework.js';
import IdxTable, { DEFAULTS } from '../utils/IdxTable.vue';
import jobStatusMapping from '../../utils/job-utils.js';
import { LaunchTrainingModal } from '../image/index.js';
import TrainingDetails from './TrainingDetails.vue';
import JobStatusView from './JobStatus.vue';

const getStatus = (trainingStatus) => {
  switch (trainingStatus) {
    case 'queued':
      return 1;
    case 'processing': // server is still processing data (hasn't sent to valohai yet), or failed during processing and will never start
      return 6;
    case 'started':
    case 'stopping':
      return 2;
    case 'stopped':
      return 8;
    case 'complete':
      return JobStatus.SUCCESS;
    case 'error':
    case 'Internal server error.':
      return JobStatus.FAILED;
    default:
      return 5;
  }
};

export default {
  name: 'ListTrainings',
  components: {
    JobStatusView,
    IdxTable,
    TrainingDetails,
    LaunchTrainingModal,
  },
  data: () => ({
    loading: true,
    error: false,
    launchModal: false,
    availableSoftwares: [],
    availableLaunchers: [],
    openTrainingModal: false,
    revision: 0,
    jobCollection: {},
    request: null,
    selectedTraining: null,

    creationModal: false,

    softwares: [],
    allTrainings: [],

    searchString: '',
    sortBy: 'created',
    sortDirection: 'desc',
    perPage: DEFAULTS.perPage,
    currentPage: 1,
    total: 0,
  }),
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {CytoProject} */
    project() {
      return this.$store.state.currentProject.project;
    },
    projectMembers() {
      return this.$store.state.currentProject.members;
    },
    /** @returns {object} */
    configUI() {
      return this.$store.state.currentProject.configUI;
    },
    /** @returns {boolean} */
    canManageProject() {
      return this.$store.getters['currentProject/canManageProject'];
    },
    /** @returns {Job[]} */
    trainings() {
      const { sortBy, sortDirection, allTrainings: trainings } = this;

      const sortDir = sortDirection === 'asc' ? 1 : -1;

      return trainings.sort((a, b) => {
        if (a[sortBy] === b[sortBy]) return 0;

        const order = a[sortBy] > b[sortBy] ? 1 : -1;
        return order * sortDir;
      });
    },
  },

  watch: {
    currentPage: {
      handler() {
        this.fetchTrainings();
      },
      immediate: true,
    },
  },

  methods: {
    availableStatus() {
      return Object.keys(jobStatusMapping).map((key) => {
        return {
          label: this.$t(jobStatusMapping[key].label),
          status: key,
        };
      });
    },

    formatTrainings(trainings) {
      const mappedTrainings = trainings.map((training) => {
        training.created = new Date(training.started_at);
        training.startedByUsername =
          this.projectMembers.find(
            (member) => member.id === parseInt(training.started_by)
          )?.username || 'deleted user';
        training.status = getStatus(training.valohai_job_status);
        return training;
      });
      return mappedTrainings;
    },

    fetchTrainingRequest() {
      return GetOutputs(this.project.id);
    },

    fetchTrainings() {
      const fetchRequest = async () => {
        try {
          /** @type {{results: Array, total: number}} */
          // @ts-ignore
          const response = await this.fetchTrainingRequest(this.project.id);
          this.allTrainings = this.formatTrainings(response);
          this.total = response.length;
        } catch (error) {
          console.log(error);
          this.error = error;
        }
      };
      this.request = fetchRequest(); // assign to a promise instead of a function, no duplicate requests
    },

    rerunTraining(training) {
      this.selectedTraining = training;
      this.openTrainingModal = true;
    },

    getDuration(trainingInstance) {
      const startDate = new Date(trainingInstance.started_at);
      const endDate = trainingInstance.finished_at
        ? new Date(trainingInstance.finished_at)
        : new Date();

      const seconds = Math.round(
        Math.abs(startDate.getTime() - endDate.getTime()) / 1000
      );

      if (seconds < 60) {
        return `${seconds}s`;
      } else {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
      }
    },
  },
};
</script>
