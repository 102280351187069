<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ title }}
      </p>
      <slot name="controls" />
    </header>
    <section class="modal-card-body">
      <slot />
    </section>
    <footer class="modal-card-foot">
      <slot name="footer">
        <IdxBtn @click="$emit('close')">
          {{ $t('close') }}
        </IdxBtn>
      </slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'CytomineModalCard',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
