var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VBtn',_vm._g(_vm._b({class:[
    _vm.typeClass,
    _vm.colorClass,
    {
      'is-small': _vm.small,
      'is-fullwidth': _vm.fullWidth,
      'color-blue': _vm.link && !_vm.color,
    } ]},'VBtn',_vm.$attrs,false),_vm.$listeners),[(_vm.loading)?_c('BIcon',{staticClass:"fa-spin",attrs:{"pack":"fas","icon":"sync-alt","aria-label":_vm.$t('loading')}}):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }