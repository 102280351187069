<script>
/**
 * @module raster-source/source
 */
import RasterSource from 'ol/source/Raster';
import tileSource from 'vuelayers/src/mixin/image-source.js';

const props = {
  sources: { type: Array },
  operation: { type: Function },
  lib: { type: Object },
};

const methods = {
  createSource() {
    return new RasterSource({
      sources: this.sources,
      operation: this.operation,
      lib: this.lib,
    });
  },
};

const watch = {
  operation() {
    this.$source.setOperation(this.operation, this.lib);
  },
  lib() {
    this.$source.setOperation(this.operation, this.lib);
  },
};

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'vl-source-raster',
  mixins: [tileSource],
  props,
  watch,
  methods,
};
</script>
