<template>
  <CytomineModal
    :active="active"
    :title="$t('algorithm-launch')"
    @close="$emit('update:active', false)"
  >
    <BLoading :is-full-page="false" :active="loading" class="small" />
    <template v-if="!loading">
      <VForm v-slot="form" class="content" @submit.prevent="uploadAndRunParam">
        <IdxInput
          v-for="(item, propertyName) in params"
          :key="item.id"
          v-model="thisAssayParams[propertyName]"
          :name="propertyName"
          :label="propertyName"
        />

        <IdxBtn
          type="submit"
          color="primary"
          :disabled="!form.valid"
          @click="creationModal = false"
        >
          {{ $t('algorithm-launch') }}
        </IdxBtn>
      </VForm>
    </template>
  </CytomineModal>
</template>

<script>
import noteApi from '../../services/noteApi.js';
import CytomineModal from '@/components/utils/CytomineModal.vue';

export default {
  name: 'LaunchJobModal',
  components: {
    CytomineModal,
  },
  props: {
    active: Boolean,
    idProject: {
      type: [String, Number],
      required: true,
    },
    assayParams: {
      type: Object,
      default: () => {},
    },
    algorithmSelected: {
      type: [String, Number],
      default: () => null,
    },
    selectedImgIds: {
      type: Array,
      default: () => [],
    },
    revision: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    thisAssayParams() {
      return this.assayParams;
    },
    params() {
      const { img_id, proj_id, ...params } = this.assayParams;
      return params;
    },
  },
  async created() {
    this.loading = false;
  },
  methods: {
    async uploadAndRunParam() {
      try {
        const requestBody = {
          params: JSON.stringify(this.thisAssayParams),
          projectId: this.idProject,
          selectedImgIds: this.selectedImgIds,
          assayId: this.algorithmSelected,
        };
        this.$notify({
          type: 'success',
          text: this.$t('algorithm-run-start'),
        });
        await noteApi.post(
          `napi/algorithm/${this.algorithmSelected}/parameter`,
          {
            data: requestBody,
          }
        );
        this.$emit('update:revision', this.revision + 1);
        this.$emit('update:active', false);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('algorithm-run-error'),
        });
      }
    },
  },
};
</script>

<style scoped>
>>> .animation-content {
  max-width: 60% !important;
  width: 60%;
}

>>> .modal-card {
  width: 100%;
  height: 80vh;
}

.image-overview {
  max-height: 4rem;
  max-width: 10rem;
}
</style>
