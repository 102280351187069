<template>
  <div v-if="permissionError || notFoundError" class="box error">
    <h2 class="mb-3">
      {{ $t(permissionError ? 'access-denied' : 'not-found') }}
    </h2>
    <p>
      {{ $t(permissionError ? 'insufficient-permission' : 'not-found-error') }}
    </p>
  </div>
  <div v-else class="content-wrapper">
    <BLoading :is-full-page="false" :active.sync="loading" />
    <div v-if="!loading" class="box">
      <i18n path="detailed-image-information" tag="h1">
        <RouterLink
          :to="`/project/${image.project}/image/${image.id}`"
          place="imageName"
        >
          <ImageName :image="image" />
        </RouterLink>
      </i18n>

      <ImageDetails
        v-if="image"
        :image="image"
        editable
        @setResolution="(resolution) => (image.resolution = resolution)"
        @setMagnification="
          (magnification) => (image.magnification = magnification)
        "
        @delete="deleteImage"
      />
    </div>
  </div>
</template>

<script>
import { ImageInstance } from 'cytomine-client';
import ImageName from './ImageName.vue';
import ImageDetails from './ImageDetails.vue';

export default {
  name: 'ImageInformation',
  components: {
    ImageName,
    ImageDetails,
  },
  data() {
    return {
      loading: true,
      image: null,
      permissionError: false,
      notFoundError: false,
    };
  },
  computed: {
    idImage() {
      return this.$route.params.idImage;
    },
  },
  watch: {
    idImage() {
      this.loadImage();
    },
  },
  created() {
    this.loadImage();
  },
  methods: {
    async loadImage() {
      this.loading = true;
      this.permissionError = false;
      this.notFoundError = false;
      try {
        this.image = await ImageInstance.fetch(this.idImage);
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          this.permissionError = true;
        } else {
          this.notFoundError = true;
        }
      }
      this.loading = false;
    },
    deleteImage() {
      this.$router.push(`/project/${this.image.project}`);
    },
  },
};
</script>
