<template>
  <form @submit.prevent="setMagnification">
    <CytomineModal
      :active="active"
      :title="$t('set-magnification')"
      @close="$emit('update:active', false)"
    >
      <BMessage type="is-warning" has-icon icon-size="is-small">
        {{ $t('warning-change-applies-in-project-only') }}
      </BMessage>

      <BField
        :label="$t('magnification')"
        :type="fieldType"
        :message="errors.first('magnification')"
      >
        <BInput
          v-model="newMagnification"
          v-validate="'required|decimal|positive'"
          name="magnification"
        />
      </BField>

      <template #footer>
        <IdxBtn @click="$emit('update:active', false)">
          {{ $t('cancel') }}
        </IdxBtn>
        <IdxBtn :disabled="errors.any()" type="submit" color="blue">
          {{ $t('save') }}
        </IdxBtn>
      </template>
    </CytomineModal>
  </form>
</template>

<script>
import noteApi from '@/services/noteApi.js';
import CytomineModal from '@/components/utils/CytomineModal.vue';

export default {
  name: 'MagnificationModal',
  components: { CytomineModal },
  props: {
    active: { type: Boolean },
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    image: { type: Object, required: true },
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      newMagnification: '',
    };
  },
  computed: {
    blindMode() {
      return this.$store.state.currentProject.project.blindMode;
    },
    fieldType() {
      return { 'is-danger': this.errors.has('magnification') };
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.newMagnification = this.image.magnification;
      }
    },
  },
  methods: {
    async setMagnification() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      const image = this.image;
      const imageName = this.blindMode
        ? image.blindedName
        : image.instanceFilename;
      try {
        const updateImage = {
          ...image,
          magnification: this.newMagnification,
        };

        await noteApi.put(`/api/imageinstance/${image.id}.json`, {
          json: updateImage,
        });

        this.$emit('setMagnification', updateImage.magnification);

        this.$notify({
          type: 'success',
          text: this.$t('notif-success-magnification-update', { imageName }),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-magnification-update', { imageName }),
        });
      }
      this.$emit('update:active', false);
    },
  },
};
</script>
