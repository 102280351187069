<template>
  <span v-if="labels[status]" :class="tagClass" class="tag">
    {{ $t(labels[status].label) }}
  </span>
</template>

<script>
import jobStatusMapping from '@/utils/job-utils.js';

export default {
  name: 'StatusMapping',
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    labels() {
      return jobStatusMapping;
    },
    result() {
      switch (this.status) {
        case 8:
          return 'dark';
        case 2:
          return 'info';
        case 3:
          return 'success';
        case 4:
          return 'danger';
        default:
          return 'light';
      }
    },
    /** @returns {string} */
    tagClass() {
      return 'is-' + this.result;
    },
  },
};
</script>
