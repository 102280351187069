<template>
  <VueSlider
    :value="value"
    :min="min"
    :max="max"
    :interval="interval"
    :min-range="minRange"
    :max-range="maxRange"
    :tooltip="tooltip"
    :tooltip-placement="tooltipPlacement"
    :enable-cross="enableCross"
    :lazy="lazy"
    @change="(values, index) => $emit('input', values, index)"
    @dragging="(values, index) => $emit('drag', values, index)"
  >
    <template #tooltip="{ value, index }">
      <div
        :class="[
          'vue-slider-dot-tooltip-inner',
          `vue-slider-dot-tooltip-inner-${tooltipPlacement[index]}`,
        ]"
        @mousedown.stop
        @click.stop="startEdition(index)"
      >
        <template v-if="indexEdited !== index">
          <span v-if="customTooltip">{{ getCustomTooltip(index) }}</span>
          <span v-else>{{ Math.round(value * 1000) / 1000 }}</span>
        </template>
        <b-input
          v-else
          ref="inputSlider"
          v-model="editedValue"
          type="text"
          @hook:mounted="focus()"
          @blur="stopEdition(index)"
          @keyup.enter.native="stopEdition(index)"
        />
      </div>
    </template>
  </VueSlider>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'CytomineSlider',
  components: { VueSlider },
  props: {
    value: { type: null },
    tooltipDirection: {
      type: String | Array,
      default: null,
    },
    customTooltip: {
      type: String | Array,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    enableCross: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: 'always', // none, always, active, hover, focus
    },
    max: {
      type: Number,
      default: 100,
    },
    interval: {
      type: Number,
      default: 1,
    },
    minRange: {
      type: Number,
      default: null,
    },
    maxRange: {
      type: Number,
      default: null,
    },
    integerOnly: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      indexEdited: null,
      editedValue: 0,
    };
  },
  computed: {
    tooltipPlacement() {
      if (this.tooltipDirection) {
        return this.tooltipDirection;
      } else {
        return Array.isArray(this.value)
          ? this.value.map((a) => 'top')
          : ['right'];
      }
    },
  },
  methods: {
    startEdition(index) {
      if (this.indexEdited !== index) {
        this.editedValue = Array.isArray(this.value)
          ? this.value[index]
          : this.value;
        this.indexEdited = index;
      }
    },
    stopEdition(index = 0) {
      if (this.indexEdited === index) {
        this.indexEdited = null;

        if (!this.editedValue || isNaN(this.editedValue)) {
          return; // if entered value is not a number, ignore
        }

        let parsedValue = this.integerOnly
          ? parseInt(this.editedValue)
          : Number(this.editedValue);

        parsedValue = Math.min(parsedValue, this.max);
        parsedValue = Math.max(parsedValue, this.min);

        // ensure min range is respected
        if (Array.isArray(this.value) && this.minRange) {
          for (let i = 0; i < this.value.length; i++) {
            if (i !== index) {
              if (Math.abs(this.value[i] - parsedValue) < this.minRange) {
                parsedValue =
                  this.value[i] <= parsedValue
                    ? this.value[i] + this.minRange
                    : this.value[i] - this.minRange;
              }
            }
          }
        }

        if (Array.isArray(this.value)) {
          let newVal = this.value.slice();
          newVal[index] = parsedValue;
          if (newVal[0] > newVal[1]) {
            // reorder bounds if needed
            newVal.reverse();
          }

          // check if min range adjustments caused us to breach min/max values
          if (newVal.some((a) => a > this.max)) {
            newVal = newVal.map((a) => a - this.minRange);
          } else if (newVal.some((a) => a < this.min)) {
            newVal = newVal.map((a) => a + this.minRange);
          }

          this.$emit('input', newVal);
        } else {
          this.$emit('input', parsedValue);
        }
      }
    },
    focus() {
      this.$refs.inputSlider.focus();
    },
    getCustomTooltip(index) {
      if (typeof this.customTooltip === 'string') {
        return this.customTooltip;
      } else {
        return this.customTooltip[index];
      }
    },
  },
};
</script>

<style lang="scss">
@import '~vue-slider-component/theme/default.css';

.vue-slider-dot-tooltip-inner {
  font-size: 0.9rem !important;
}

.vue-slider {
  margin-left: 4rem;
  margin-right: 6rem;
}

.vue-slider-dot-tooltip input {
  width: 4rem;
  height: 1.5rem;
  font-size: 0.8rem;
}
</style>
