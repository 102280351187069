<template>
  <CytomineModalCard
    :title="$t('description')"
    :class="{ expanded: expanded }"
    class="description-modal"
    @close="$parent.close()"
  >
    <template #controls>
      <button class="button is-small" @click="expanded = !expanded">
        <i :class="['fas', expanded ? 'fa-compress' : 'fa-expand']" />
      </button>
    </template>

    <div v-if="!edit" class="ql-snow">
      <div class="ql-editor preview" v-html="descriptionWithoutKeywords" />
    </div>

    <template v-else>
      <!-- <div class="keyword-info">
        <i class="fas fa-info-circle" />
        <I18n path="info-keyword-stop-preview-description">
          <span place="keyword" class="weight-6">
            {{ stopPreviewKeyword }}
          </span>
        </I18n>
      </div> -->

      <CytomineQuillEditor
        v-model="descriptionContent"
        :placeholder="$t('enter-description')"
      />
    </template>

    <template v-if="edit" #footer>
      <button class="button" @click="$parent.close()">
        {{ $t('cancel') }}
      </button>
      <button v-if="edit" class="button is-link" @click="save()">
        {{ $t('save') }}
      </button>
    </template>
  </CytomineModalCard>
</template>

<script>
import CytomineQuillEditor from '@/components/form/CytomineQuillEditor.vue';
import CytomineModalCard from '@/components/utils/CytomineModalCard.vue';
import constants from '@/utils/constants.js';

export default {
  name: 'CytomineDescriptionModal',
  components: {
    CytomineModalCard,
    CytomineQuillEditor,
  },
  props: {
    description: {
      type: Object,
      required: true,
    },
    edit: Boolean,
  },
  data() {
    return {
      descriptionContent: '',
      expanded: false,
    };
  },
  computed: {
    descriptionWithoutKeywords() {
      return this.description.data.replace(
        new RegExp(constants.STOP_PREVIEW_KEYWORD, 'g'),
        ''
      );
    },
    stopPreviewKeyword() {
      return constants.STOP_PREVIEW_KEYWORD;
    },
  },
  created() {
    this.descriptionContent = this.description.data;
  },
  methods: {
    async save() {
      const updatedDesc = this.description.clone();
      try {
        if (this.descriptionContent) {
          updatedDesc.data = this.descriptionContent;
          await updatedDesc.save();
          this.$emit('change', updatedDesc);
        } else if (this.description.data) {
          // if description existed, and content was emptied by user, delete description model
          await updatedDesc.delete();
          this.$emit('change', null);
        }
        this.$parent.close();
      } catch (error) {
        this.descriptionContent = this.description.data;
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-update-description'),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.description-modal {
  &.expanded,
  &.expanded .modal-card-body {
    width: 90vw;
    height: 90vh;
    max-height: 90vh;
  }

  &:not(.expanded),
  &:not(.expanded) .modal-card-body {
    width: 50vw;
    height: 60vh;
    max-height: 60vh;
  }

  .ql-editor.preview {
    padding: 0 0 1em 0;
    text-align: justify;
    white-space: normal;
  }

  .modal-card-body {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .keyword-info {
    margin-bottom: 1em;

    .fas {
      margin-right: 0.75em;
    }
  }

  .cytomine-quill-editor {
    flex: 1;
  }
}
</style>
