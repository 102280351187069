<template>
  <div
    v-if="images && images.length"
    class="grid gap-16"
    :style="`--columns: ${nbRecent}`"
  >
    <ImagePreview
      v-for="image in images"
      :key="image.image"
      :image="image"
      :blind-mode="project.blindMode"
    />
    <div class="flex align-center justify-center">
      <RouterLink :to="`/project/${project.id}/images`" class="button">
        {{ $t('button-view-all') }}
      </RouterLink>
    </div>
  </div>
  <div v-else>
    {{ $t('no-image-recently-opened') }}
  </div>
</template>

<script>
import { ImageInstanceCollection } from 'cytomine-client';

import ImagePreview from './ImagePreview.vue';

export default {
  name: 'ListImagesPreview',
  components: { ImagePreview },
  props: {
    /** @type {import('vue').PropOptions<CytoProject>} */
    project: {
      type: Object,
      required: true,
    },
    nbRecent: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      images: [],
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
  },
  async created() {
    this.images = await ImageInstanceCollection.fetchLastOpened({
      max: this.nbRecent,
      project: this.project.id,
      user: this.currentUser.id,
    });
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: $tablet) {
  .grid {
    grid-template-columns: repeat(var(--columns), 200px) auto;
  }
}
</style>
