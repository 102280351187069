<script>
/** @module translate-interaction/interaction */
import TranslateInteraction from 'ol/interaction/Translate.js';
import { fromOlEvent as observableFromOlEvent } from 'vuelayers/src/rx-ext/from-ol-event.js';
import interaction from 'vuelayers/src/mixin/interaction.js';
import { isCollection } from 'vuelayers/src/ol-ext/util.js';
import { isFunction } from 'vuelayers/src/util/minilo.js';
import { hasInteraction } from 'vuelayers/src/util/assert.js';
import { makeWatchers } from 'vuelayers/src/util/vue-helpers.js';

/**
 * @vueProps
 */
const props = {
  /**
   * Source or collection identifier from IdentityMap.
   *
   * @type {string}
   */
  source: {
    type: String,
    required: true,
  },
  /**
   * Hit-detection tolerance. Pixels inside the radius around the given position will be checked for features.
   * This only works for the canvas renderer and not for WebGL.
   *
   * @type {number}
   */
  hitTolerance: {
    type: Number,
    default: 0,
  },
};

/**
 * @vueMethods
 */
const methods = {
  /**
   * @returns {Promise<ol.interaction.Translate>}
   * @protected
   */
  async createInteraction() {
    const sourceIdent = this.makeIdent(this.source);
    let source = await this.$identityMap.get(
      sourceIdent,
      this.$options.INSTANCE_PROMISE_POOL
    );
    if (isFunction(source.getFeatures)) {
      const features = source.getFeatures();
      if (isCollection(features)) {
        source = features;
      }
    }
    return new TranslateInteraction({
      // source: isVectorSource(source) ? source : undefined,
      features: isCollection(source) ? source : undefined,
      hitTolerance: this.hitTolerance,
    });
  },
  /**
   * @returns {void}
   * @protected
   */
  mount() {
    interaction.methods.mount.call(this);
  },
  /**
   * @returns {void}
   * @protected
   */
  unmount() {
    interaction.methods.unmount.call(this);
  },
  /**
   * @returns {void}
   * @protected
   */
  subscribeAll() {
    subscribeToInteractionChanges.call(this);
  },
};

const watch = makeWatchers(
  ['source'],
  () =>
    function () {
      this.scheduleRecreate();
    }
);

/**
 * @vueProto
 * @alias module:translate-interaction/interaction
 * @title vl-interaction-translate
 */
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'vl-interaction-translate',
  mixins: [interaction],
  props,
  watch,
  methods,
};

/**
 * @private
 */
function subscribeToInteractionChanges() {
  hasInteraction(this);
  const translateEvents = observableFromOlEvent(this.$interaction, [
    'translatestart',
    'translateend',
  ]);
  this.subscribeTo(translateEvents, (evt) => {
    ++this.rev;
    this.$emit(evt.type, evt);
  });
}
</script>
