import dayjs from 'dayjs';

/**
 * @param {{
 * firstname: string
 * lastname: string
 * username: string
 * created: string | number
 * algo: string
 * softwareName: string
 * }} user
 * @returns {string}
 */
export function fullName(user) {
  if (!user) {
    return '';
  }

  if (!user.algo) {
    return `${user.firstname} ${user.lastname} (${user.username})`;
  } else {
    const date = dayjs(Number(user.created)).format('L LTS');
    return `${user.softwareName} - ${date}`;
  }
}
