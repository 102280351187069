<template>
  <CytomineModal
    :active="active"
    :title="$t('image-metadata')"
    @close="$emit('update:active', false)"
  >
    <BMessage v-if="error" type="is-danger" has-icon icon-size="is-small">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </BMessage>
    <template v-else>
      <template v-if="image && image.macroURL">
        <p :style="styleImagePreview" class="mx-auto mb-2 text-center">
          <img
            ref="image"
            :class="'rotate-' + rotationAngle"
            :src="image.macroURL"
            :alt="blindMode ? image.blindedName : image.originalFilename"
          />
        </p>
        <div class="buttons is-centered are-small">
          <IdxBtn @click="rotate(-90)">
            <i class="fas fa-undo" />
          </IdxBtn>
          <IdxBtn @click="rotate(90)">
            <i class="fas fa-redo" />
          </IdxBtn>
        </div>
      </template>

      <label for="metadata-modal-filter">
        {{ $t('search') }}
        <BInput
          id="metadata-modal-filter"
          v-model="searchString"
          type="search"
          icon="search"
          size="is-small"
          class="mb-2"
        />
      </label>
      <ul>
        <li v-for="prop in filteredProps" :key="prop.id">
          <strong>{{ prop.key.replace('cytomine', 'image') }}</strong
          >: {{ prop.value }}
        </li>
      </ul>
    </template>

    <template #footer>
      <IdxBtn type="button" @click="$emit('update:active', false)">
        {{ $t('close') }}
      </IdxBtn>
    </template>
  </CytomineModal>
</template>

<script>
import CytomineModal from '@/components/utils/CytomineModal.vue';
import { getWildcardRegexp } from '@/utils/string-utils.js';
import noteApi from '@/services/noteApi.js';

export default {
  name: 'ImageMetadataModal',
  components: { CytomineModal },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    blindMode: {
      type: Boolean,
      default: false,
    },
    /** @type {import('vue').PropOptions<CytoImageInstance>} */
    image: { type: Object, required: true },
  },
  data() {
    return {
      error: false,
      /** @type {CytoProperty[]} */
      properties: [],
      searchString: '',
      rotationAngle: 0,
    };
  },
  computed: {
    /** @returns {CytoProperty[]} */
    filteredProps() {
      if (!this.searchString) {
        return this.properties;
      }
      const regexp = getWildcardRegexp(this.searchString);
      return this.properties.filter(
        (prop) => regexp.test(prop.key) || regexp.test(prop.value)
      );
    },
    /** @returns {{ width: string, height: string }} */
    styleImagePreview() {
      this.rotationAngle; // to force re-evaluation each time rotationAngle changes
      if (!this.$refs.image) {
        return;
      }

      const reverse = this.rotationAngle === 90 || this.rotationAngle === 270;
      const width = this.$refs.image.clientWidth + 'px';
      const height = this.$refs.image.clientHeight + 'px';
      return {
        width: reverse ? height : width,
        height: reverse ? width : height,
      };
    },
  },
  async created() {
    try {
      const response = await noteApi.get(
        `/api/domain/be.cytomine.image.AbstractImage/${this.image.baseImage}/property.json?max=0&offset=0`
      );
      this.properties = response.collection.sort((a, b) =>
        a.key.localeCompare(b.key)
      );
    } catch (error) {
      console.log(error);
      this.error = true;
    }
  },
  methods: {
    /** @param {number} val */
    async rotate(val) {
      this.rotationAngle = (this.rotationAngle + val + 360) % 360;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  transform-origin: top left;
  max-width: initial;

  &.rotate-90 {
    transform: rotate(90deg) translateY(-100%);
  }

  &.rotate-180 {
    transform: rotate(180deg) translate(-100%, -100%);
  }

  &.rotate-270 {
    transform: rotate(270deg) translateX(-100%);
  }
}

li {
  overflow-wrap: break-word;
}
</style>
