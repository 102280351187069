<template>
  <CytomineModalCard :title="$t('shortcuts')" @close="$parent.close()">
    <div v-for="category in categories" :key="category">
      <h2 class="mb-3">
        {{ $t(`shortcut-${category}`) }}
      </h2>
      <BTable :data="filteredShortcuts(category)">
        <BTableColumn :label="$t('description')" field="name">
          <template #default="props">
            {{ $t(`shortcut-${props.row.name}`) }}
          </template>
        </BTableColumn>

        <BTableColumn :label="$t('shortcut')" field="val">
          <template #default="props">
            <span
              v-for="(k, index) in props.row.key"
              :key="`${category}${props.row.name}${k}`"
            >
              <span
                class="
                  key
                  inline-block
                  border border-gray-4
                  radius-4
                  py-2
                  px-4
                  bg-gray-1
                  weight-6
                "
              >
                <i v-if="k === 'click'" class="fas fa-mouse-pointer" />
                <i v-else-if="k === 'drag'" class="fas fa-hand-pointer" />
                <i v-else-if="k === '+'" class="fas fa-plus" />
                <i v-else-if="k === '-'" class="fas fa-minus" />
                <i v-else-if="k === 'arrowleft'" class="fas fa-arrow-left" />
                <i v-else-if="k === 'arrowright'" class="fas fa-arrow-right" />
                <i v-else-if="k === 'arrowup'" class="fas fa-arrow-up" />
                <i v-else-if="k === 'arrowdown'" class="fas fa-arrow-down" />
                <span v-else-if="k === 'meta'">&#8984; Cmd</span>
                <span v-else-if="k === 'shift'">&#8679; Shift</span>
                <span v-else-if="k === 'alt'">&#8997; Alt</span>
                <span v-else-if="k === 'pageup'">Page Up</span>
                <span v-else-if="k === 'pagedown'">Page Down</span>
                <span v-else-if="k === 'del'">Delete</span>
                <span v-else>{{ k }}</span>
              </span>
              <template v-if="index < props.row.key.length - 1">+</template>
            </span>
          </template>
        </BTableColumn>
      </BTable>
    </div>
  </CytomineModalCard>
</template>

<script>
import CytomineModalCard from '@/components/utils/CytomineModalCard';
import shortcuts from '@/utils/shortcuts.js';

export default {
  name: 'HotkeysModal',
  components: { CytomineModalCard },
  data() {
    return {
      categories: [
        'general',
        'viewer-nav',
        'viewer-tool',
        'viewer-toggle',
        'text-editor',
      ],
    };
  },
  computed: {
    shortcuts() {
      return Object.entries(shortcuts).map(([name, key]) => {
        return {
          name,
          key,
        };
      });
    },
  },
  methods: {
    filteredShortcuts(category) {
      return this.shortcuts.filter((shortcut) => {
        return shortcut.name.startsWith(`${category}-`);
      });
    },
  },
};
</script>

<style scoped>
.key {
  text-transform: capitalize;
}

>>> td,
>>> th {
  vertical-align: middle !important;
  width: 50%;
}
</style>
