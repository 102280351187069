<template>
  <div class="content-wrapper">
    <BLoading :is-full-page="false" :active.sync="loading" />

    <div class="gradient-banner flex align-center py-1 has-text-white" />

    <template v-if="!loading">
      <div class="box">
        {{ `Welcome ${currentUser.firstname}` }}
      </div>
      <div class="columns">
        <div class="column">
          <div class="box recents">
            <h2 class="mb-3">
              {{ $t('recently-opened') }}
            </h2>
            <BMessage
              v-if="!recentProjects"
              type="is-danger"
              has-icon
              icon-size="is-small"
            >
              {{ $t('failed-fetch-recent-projects') }}
            </BMessage>
            <BTable v-else :data="recentProjects">
              <BTableColumn
                v-slot="props"
                :label="$t('study')"
                width="100"
                centered
              >
                <RouterLink :to="`/project/${props.row.id}`">
                  {{ props.row.name }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn v-slot="props" :label="$t('images')" width="400">
                <ListImagesPreview :project="props.row" />
              </BTableColumn>

              <template #empty>
                <div class="content has-text-grey has-text-centered">
                  <p>{{ $t('project-no-recent') }}</p>
                </div>
              </template>
            </BTable>
          </div>
        </div>
      </div>
      <ListProjects class="py-0 px-0" />
    </template>
  </div>
</template>

<script>
import { ImageInstanceCollection, ProjectCollection } from 'cytomine-client';

import ListImagesPreview from '@/components/image/ListImagesPreview.vue';
import ListProjects from '@/components/project/ListProjects.vue';

export default {
  name: 'GlobalDashboard',
  components: {
    ListImagesPreview,
    ListProjects,
  },
  props: {
    nbRecent: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      projects: null,
      recentProjects: null,
      welcomeMessage: null,
      loading: true,
      showModal: false,
    };
  },
  computed: {
    /** @returns {object} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
  },
  async created() {
    await Promise.all(
      [this.fetchRecentProjects()].map((p) => p.catch((e) => console.log(e)))
    ); // ignore errors (handled in template) and ensure all promises finish, even if some errors occur in the process
    this.loading = false;
  },
  methods: {
    async fetchRecentProjects() {
      const listRecent = await ProjectCollection.fetchLastOpened(this.nbRecent);
      this.recentProjects = listRecent.map((recent) => ({
        id: recent.id,
        name: recent.name,
      }));
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: middle !important;
}

.gradient-banner {
  margin: -1.5% -2.5% 1.5%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  background-image: linear-gradient(to right top, #28d8fe, #2391ff);
}

.contact-link {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
  font-weight: 600;
  text-decoration: none;
}

.contact-link:hover,
.contact-link.is-hovered {
  border-color: transparent;
  color: #363636;
}

.recents {
  /* Matches height of Statistics box when there are no recent images */
  min-height: calc(10rem + 1px);
}
</style>
